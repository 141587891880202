import React, { useState, useEffect } from 'react';
import { jsx, Container } from "theme-ui";
import ContentfulComponent from './ContentfulComponent';
import RichText2 from './RichText2';

export const DynamicSection = ({ selectors, dynamicContentBlocks, locale, language }) => {

    const data = selectors.data && selectors.data.internal && selectors.data.internal.content ? JSON.parse(selectors.data.internal.content) : {};

    // selectorState holds the current state of buttons (what the user has chosen in the UI)
    const [selectorState, setSelectorState] = useState([]);
    // filteredBlocks is used as a helper array to determine which articles are shown according to selected values
    const [filteredBlocks, setFilteredBlocks] = useState([]);
    // This is set to true when all the selections have been made -> dynamic content blocks are rendered
    const [displayBlocks, setDisplayBlocks] = useState(false);

    // Get initial question fields
    useEffect(() => {
        setSelectorState(data.formFields)
    }, []);

    // Handler for anwering a question
    const handleSetSelectorValue = (newValue, index) => {
        let newState = [...selectorState];
        newState[index].currentValue = newValue;
        setSelectorState(newState);
    }

    // Filter blocks according to answers that are given
    useEffect(() => {
        let newBlocks = [...filteredBlocks];
        selectorState.forEach((item) => {
            item.contentBlocks.forEach((block) => {
                const id = block.id;
                const showIf = block.showIf;
                const currentValue = item.currentValue;
                if (showIf.includes(currentValue)) {
                    newBlocks.push(id);
                } else {
                    newBlocks = newBlocks.filter(arrayItem => arrayItem !== block.id);
                }
            })
        })
        newBlocks = [...new Set(newBlocks)]; // only use unique values
        setFilteredBlocks(newBlocks);
    }, [selectorState])

    // Only display articles if all the questions are answered (all have a currentValue)
    useEffect(() => {
        let display = true;
        selectorState.forEach((question) => {
            !question.currentValue ? display = false : "";
        })
            setDisplayBlocks(display);
        }
    , [filteredBlocks])

    return (
        <Container variant="wide" sx={{mb: 0, mt: 0}}>
            <ContentfulComponent
                template="DynamicSelectors"
                data={data}
                locale={locale}
                language={language}
                selectedValue={selectorState}
                callback={handleSetSelectorValue}
            />
            {displayBlocks && dynamicContentBlocks.map((item, index) => {
                if (filteredBlocks.includes(item.blockId)) {
                    return (
                        <RichText2
                            key={`dynamic-selector-${index}}`}
                            sx={{mb: 0, mt: 0}}
                            content={item.content}
                            locale={locale}
                            language={language} />
                    );
                }
            })}
        </Container>
    )
};
