/** @jsx jsx */
import { jsx, Box, Themed, Flex } from "theme-ui";
import Link from "./Link";
import RichText2 from "./RichText2";

const LiftupEasyStep = ({
  title,
  actionText,
  actionUrl,
  actionReference,
  firstElement,
  secondElement,
  thirdElement,
  locale,
  language,
}) => {
  return (
    <Box
      sx={{
        bg: "lightGreen",
        p: [3, 4],
        "@media print": {
          breakInside: "avoid",
        },
      }}
    >
      <Themed.h2 sx={{ mt: 0 }}>{title}</Themed.h2>
      <Flex sx={{ flexWrap: ["wrap", "nowrap"], mx: -3, my: 3 }}>
        {[firstElement, secondElement, thirdElement].map((element, index) => (
          <Box
            key={index}
            sx={{
              width: ["100%", null, "33.33333%"],
              px: 3,
              fontSize: 3,
              "@media print": {
                width: "33.33333%",
              },
            }}
          >
            <RichText2 content={element} locale={locale} language={language} />
          </Box>
        ))}
      </Flex>
      {actionUrl && actionUrl !== "http://n-a" && (
        <Link
          variant="buttons.plain"
          target={actionUrl}
          showArrow
          sx={{
            fontSize: 3,
          }}
        >
          {actionText}
        </Link>
      )}
      {actionReference && (
        <Link
          variant="buttons.plain"
          target={actionReference.slug}
          showArrow
          sx={{
            fontSize: 3,
          }}
        >
          {actionText}
        </Link>
      )}
    </Box>
  );
};

export default LiftupEasyStep;
