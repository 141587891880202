/** @jsx jsx */
import { jsx, Themed, Flex } from "theme-ui";
import Link from "./Link";

const ListOfLinks = ({ data, anchor, ...rest }) => {
  const { listOfLinks } = data;
  return (
    <Flex
      sx={{
        flexDirection: ["column", "row"],
        flexWrap: "wrap",
        scrollMarginTop: "104px"
      }}
      id={anchor}
    >
      {listOfLinks.map(
        ({ title, url, analyticsAction, analyticsId }, index) => {
          return (
            <Link
              key={index}
              analyticsAction={analyticsAction}
              analyticsId={analyticsId}
              {...rest}
              target={url}
              sx={{
                display: "block",
                width: ["100%", "50%"],
                pr: [0, 1],
                mb: [1, 0],
                fontWeight: "bold",
              }}
            >
              {title}
            </Link>
          );
        }
      )}
    </Flex>
  );
};

export default ListOfLinks;
