/** @jsx jsx */
import { jsx, Box, Flex, Themed } from "theme-ui";
import { useLatestContentfulArticles } from "../hooks/useContentfulArticles";
import Link from "../components/Link";

const ArticleCard = ({ title, publishDateInSeconds, slug }) => {
  const publishDate = new Date(+publishDateInSeconds * 1000);
  return (
    <Link
      target={"/" + slug}
      variant="buttons.plain"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: ["100%", "50%", null, "25%"],
        px: 3,
        mb: [4, null, null, 0],
      }}
    >
      <Box sx={{ fontSize: 3, mb: 1 }}>{title}</Box>
      <Box sx={{ fontSize: 0, color: "text" }}>
        {publishDate.getDate()}.{publishDate.getMonth() + 1}.
        {publishDate.getFullYear()}
      </Box>
    </Link>
  );
};

const ArticleList = ({ data, language, anchor }) => {
  const allLatestArticles = useLatestContentfulArticles();
  let latestArticles = allLatestArticles[language];
  if ((data.split && latestArticles.length) > data.split) {
    latestArticles = latestArticles.slice(0, data.split);
  }
  const { linkTitle, linkSlug } = data;
  return (
    <Box
      sx={{
        bg: data.split ? "muted" : "background",
        mt: [3, 4, 5],
        p: [3, 4],
        mx: [-3, 0],
        scrollMarginTop: "104px",
      }}
      id={anchor}
    >
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
        }}
      >
        {data.title && (
          <Themed.h2
            sx={{
              mt: 0,
              mb: 4,
            }}
          >
            {data.title}
          </Themed.h2>
        )}
        {linkTitle && linkSlug && (
          <Link
            target={linkSlug}
            variant="buttons.plain"
            showArrow
            sx={{
              display: "inline-block",
              ml: [null, "auto", "auto", "auto"],
              mt: 0,
              mb: 3,
              fontSize: 3,
            }}
          >
            {linkTitle}
          </Link>
        )}
      </Flex>
      <Flex
        sx={{
          flexDirection: data.split ? "row" : "column",
          flexWrap: "wrap",
          mx: -3,
        }}
      >
        {latestArticles.map(({ node }) => {
          const { id, title, publishDate, slug, parentPage } = node;
          const parentPageSlugUrl =
            parentPage && parentPage.slug ? parentPage.slug : "/";
          //const { slug: parentPageSlug } = parentPage;
          return (
            <ArticleCard
              key={id}
              title={title}
              publishDateInSeconds={publishDate}
              slug={`${parentPageSlugUrl}/${slug}`}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default ArticleList;
