/** @jsx jsx */
import { jsx, Flex, Box, Themed } from "theme-ui";
import { useState, useEffect } from "react";
import Link from "../components/Link";
import { LogoBG } from "../components/Icon";

export const Types = {
  FULL: "Full",
  BUTTON: "Button",
  BUTTON_SECONDARY: "ButtonSecondary",
  ARROWRIGHT: "ArrowRight",
  FULL_BUTTON: "Full Button",
};

const renderFullCallToAction = (
  slug,
  { title, description, linkText, target, color, ...rest },
  isLink = true
) => (
  <Flex
    sx={{
      flexDirection: "column",
      justifyContent: "space-between",
      color: "highlight",
      bg: color ? "muted" : "primary",
      position: "relative",
      overflow: "hidden",
      p: [3, 4],
      mx: color ? [-3, 0] : 0,
    }}
  >
    <Flex
      sx={{
        flexDirection: "column",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Themed.h2
        sx={{
          color: color ? "black" : "white",
          mt: 0,
          mb: 3,
        }}
        {...rest}
      >
        {title}
      </Themed.h2>
      {description && (
        <Themed.p sx={{ color: "background", flex: "1 1 auto", mt: 0, mb: 3 }}>
          {description}
        </Themed.p>
      )}
      <Box>
        {color && (
          <Link
            variant="buttons.plain"
            target={slug ? slug : target}
            {...rest}
            showArrow={isLink}
            sx={{ fontSize: 3 }}
          >
            {linkText}
          </Link>
        )}
        {!color && (
          <Link
            target={slug ? slug : target}
            sx={{
              variant: isLink ? "buttons.primary" : "buttons.light",
              whiteSpace: "normal",
            }}
            {...rest}
            showArrow={isLink}
          >
            {linkText}
          </Link>
        )}
      </Box>
    </Flex>
    {color && (
      <Box
        sx={{
          position: "absolute",
          height: "auto",
          width: 220,
          top: "-82px",
          right: [2, null, 4],
          zIndex: 0,
          opacity: 0.12,
          objectFit: "cover",
        }}
      >
        <LogoBG />
      </Box>
    )}
  </Flex>
);

const renderButtonCallToAction = (
  slug,
  { linkText, target, ...rest },
  variant
) => (
  <Link
    target={slug ? slug : target}
    sx={{
      variant: variant ? variant : "buttons.primary",
      whiteSpace: "normal",
    }}
    {...rest}
  >
    {linkText}
  </Link>
);

const renderArrowCallToAction = (slug, { linkText, target, ...rest }) => (
  <Link
    target={slug ? slug : target}
    {...rest}
    sx={{
      fontWeight: "bold",
    }}
    showArrow
  >
    {linkText}
  </Link>
);

const CallToAction = ({ link, type, locale = "fi-FI", ...rest }) => {
  let slug = link && link.slug ? link.slug : rest.target;
  const [isClient, setIsClient] = useState(false);

  // Disable SSR
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient === false) {
    return "";
  }

  switch (type) {
    case Types.FULL_BUTTON:
      return renderFullCallToAction(slug, rest, false);
    case Types.FULL:
      return renderFullCallToAction(slug, rest);
    case Types.ARROWRIGHT:
      return renderArrowCallToAction(slug, rest);
    case Types.BUTTON_SECONDARY:
      return renderButtonCallToAction(slug, rest, "buttons.secondary");
    case Types.BUTTON:
    default:
      return renderButtonCallToAction(slug, rest);
  }
};

export default CallToAction;
