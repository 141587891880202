/** @jsx jsx */
import { jsx, Themed, Flex, Box } from "theme-ui";
import Link from "../components/Link";
import { GatsbyImage } from "gatsby-plugin-image";
import ResponsiveIframe from "../components/ResponsiveIframe";

const Types = {
  FRONT: "front",
  THEME: "theme",
  PRODUCT: "product",
  SECONDARY: "secondary",
  CAMPAIGN: "campaign",
  FRONTWITHBUTTON: "frontWithButton",
  FRONTEXTENDED: "frontExtended",
  FRONTWITHBUTTONEXTENDED: "frontWithButtonExtended",
  CAMPAIGNWITHBUTTON: "campaignWithButton",
  TIMELESSIMAGELEFT: "timelessImageLeft",
  TIMELESSIMAGERIGHT: "timelessImageRight",
};

const HeroCallToAction = ({
  linkText,
  target,
  link,
  variant = "buttons.primary",
  showArrow,
  ...rest
}) => {
  if (!linkText) return null;
  return (
    <Link
      variant={variant}
      showArrow={showArrow}
      {...rest}
      target={link ? link.slug : target}
      sx={{ whiteSpace: "normal " }}
    >
      {linkText}
    </Link>
  );
};

const getProps = (videoUrl, type) => {
  const getVideoBorderRadius = (type) => {
    if (type === Types.SECONDARY) {
      return {
        borderTopLeftRadius: imageBorderRadius(type),
      };
    }

    return {
      borderTopRightRadius: imageBorderRadius(type),
    };
  };

  if (videoUrl)
    return {
      ...getVideoBorderRadius(type),
      overflow: "hidden",
      minHeight: "auto",
    };

  return {
    height: ["auto", null, "26.25rem"], // 420
  };
};

const imageBorderRadius = (type) => {
  if (
    type === Types.FRONT ||
    type === Types.FRONTWITHBUTTON ||
    type === Types.FRONTEXTENDED ||
    type === Types.FRONTWITHBUTTONEXTENDED
  )
    return 100;
  else if (type === Types.THEME) return 202;
  else if (type === Types.PRODUCT) return 202;
  else if (type === Types.SECONDARY) return 100;
  else if (type === Types.CAMPAIGN || type === Types.CAMPAIGNWITHBUTTON)
    return 100;
  else return 0;
};

const HeroText = ({ title, description, type, ...rest }) => {
  switch (type) {
    case Types.FRONT:
    case Types.FRONTWITHBUTTON:
    case Types.FRONTEXTENDED:
    case Types.FRONTWITHBUTTONEXTENDED:
      return (
        <Flex
          sx={{
            position: [null, "absolute", "absolute"],
            order: [1, null, null],
            top: ["auto", null, 0],
            right: [0, "30%", "50%"],
            bottom: 0,
            left: 0,
            flexDirection: "column",
            justifyContent: "center",
            bg: "rgba(255, 255, 255, 0.9)",
            pl: [3, 4],
            pr: [3, 7, 6],
            borderTopRightRadius: [0, 202, 202],
            zIndex: [null, 2, 2],
            paddingTop: [1, 4, 4],
            paddingBottom: [0, 3, 4],
          }}
        >
          <Themed.h1
            sx={{
              color: "primary",
              mb: [1, 1, 1],
              mt: 2,
            }}
          >
            {title}
          </Themed.h1>
          <Box
            sx={{
              mb: 3,
              fontSize:
                type === Types.FRONTWITHBUTTONEXTENDED ||
                type === Types.FRONTEXTENDED
                  ? [2, 3, 3, 4]
                  : [4, null, 5],
              lineHeight:
                type === Types.FRONTWITHBUTTONEXTENDED ||
                type === Types.FRONTEXTENDED
                  ? [1.3, 1.35, 1.35, 1.4]
                  : [1.4, null, 1.3],
              fontWeight: "bold",
            }}
          >
            {description}
          </Box>
          <Box>
            <HeroCallToAction
              variant={
                type === Types.FRONT || type === Types.FRONTEXTENDED
                  ? "buttons.plain"
                  : "buttons.primary"
              }
              showArrow={
                type === Types.FRONT || type === Types.FRONTEXTENDED
                  ? true
                  : false
              }
              {...rest}
            />
          </Box>
        </Flex>
      );
    case Types.SECONDARY:
      return (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            order: 1,
            width: ["100%", null, "50%"],
            px: [3, 4],
          }}
        >
          <Themed.h2
            sx={{
              color: "plain",
              mb: 2,
              mt: [3, null, 0],
            }}
          >
            {title}
          </Themed.h2>
          <Box
            sx={{
              mb: 3,
            }}
          >
            {description}
          </Box>
          <Box>
            <HeroCallToAction
              {...rest}
              variant="buttons.primary"
              sx={{ color: "white" }}
            />
          </Box>
        </Flex>
      );
    case Types.CAMPAIGN:
    case Types.CAMPAIGNWITHBUTTON:
      return (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            order: 1,
            width: ["100%", null, "50%"],
            px: [3, 4],
          }}
        >
          <Themed.h2
            sx={{
              color: "plain",
              mb: 2,
              mt: [3, null, 0],
            }}
          >
            {title}
          </Themed.h2>
          <Box
            sx={{
              mb: 3,
            }}
          >
            {description}
          </Box>
          <Box>
            <HeroCallToAction
              {...rest}
              variant={
                type === Types.CAMPAIGN ? "buttons.plain" : "buttons.primary"
              }
              showArrow={type === Types.CAMPAIGN ? true : false}
            />
          </Box>
        </Flex>
      );
    case Types.TIMELESSIMAGELEFT:
    case Types.TIMELESSIMAGERIGHT:
      return (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            bg: "primary",
            borderTopRightRadius: type === Types.TIMELESSIMAGELEFT ? 50 : null,
            borderTopLeftRadius: type === Types.TIMELESSIMAGELEFT ? null : 50,
            order: type === Types.TIMELESSIMAGELEFT ? 1 : 0,
            width: ["100%", null, "50%"],
            px: [3, 4],
            pt: [2, 2, 0],
            pb: [4, 4, 0],
          }}
        >
          <Themed.h3
            sx={{
              color: "white",
              mb: 2,
              mt: [3, null, 0],
            }}
          >
            {title}
          </Themed.h3>
          <Box
            sx={{
              mb: 3,
              color: "white",
            }}
          >
            {description}
          </Box>
          <Box>
            <HeroCallToAction
              {...rest}
              variant={"buttons.light"}
              showArrow={type === Types.CAMPAIGN ? true : false}
            />
          </Box>
        </Flex>
      );
    default:
      return (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: ["100%", null, "50%"],
            pr: [0, null, 3],
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              bg: type === Types.THEME ? "primary" : "none",
              px: type === Types.THEME ? [3, 4] : [3, 4, 0],
              borderTopRightRadius: 202,
              zIndex: 2,
              height: ["auto", null, "100%"],
              pt: 5,
              pb: type === Types.PRODUCT ? [0, null, 5] : 5,
            }}
          >
            <Themed.h1
              sx={{
                mt: 0,
                mb: 4,
                color: type === Types.THEME ? "white" : "text",
              }}
            >
              {title}
            </Themed.h1>
            <Box
              sx={{
                color: type === Types.THEME ? "white" : "text",
                mb: 4,
              }}
            >
              {description}
            </Box>
            <Box>
              <HeroCallToAction
                sx={{ color: "white" }}
                variant={
                  type === Types.THEME ? "buttons.light" : "buttons.primary"
                }
                type={type}
                {...rest}
              />
            </Box>
          </Flex>
        </Box>
      );
  }
};

const HeroMedia = ({ image, videoEmbedUrl, videoEmbedTitle, type, title }) => {
  if (!image) return null;
  //TODO: maybe better display for svg images
  if (image.file && image.file.contentType === "image/svg+xml") {
    return <img src={image.file.url} />;
  }

  const imageHeightSetter = (titleLength, type) => {
    if (
      type === Types.FRONTEXTENDED ||
      type === Types.FRONTWITHBUTTONEXTENDED
    ) {
      if (titleLength > 80) {
        return ["16rem", "34.25rem", "44.25rem", "36.25rem"];
      } else if (titleLength > 50) {
        return ["16rem", "30.25rem", "36.25rem", "30.25rem"];
      } else {
        return ["16rem", "28.25rem", "30.25rem", "28.25rem"];
      }
    }
    return ["16rem", "26.25rem", "26.25rem", "26.25rem"];
  };

  switch (type) {
    case Types.FRONT:
    case Types.FRONTWITHBUTTON:
    case Types.FRONTEXTENDED:
    case Types.FRONTWITHBUTTONEXTENDED:
      return (
        <GatsbyImage
          image={image.gatsbyImageData}
          sx={{
            width: "100%",
            height: imageHeightSetter(title.length, type),
            borderTopRightRadius: [imageBorderRadius(type), 0, 0],
            zIndex: 1,
          }}
        />
      );
    case Types.PRODUCT:
      return (
        <Box
          sx={{
            flex: "none",
            width: "50%",
            display: ["none", "none", "block"],
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            sx={{
              borderTopLeftRadius: imageBorderRadius(type),
              overflow: "hidden",
              minHeight: [400, null, "auto"],
              height: ["auto", null, "100%"],
              zIndex: 1,
            }}
          />
        </Box>
      );
    case Types.CAMPAIGN:
    case Types.CAMPAIGNWITHBUTTON:
      return (
        <Box
          sx={{
            flex: "none",
            order: [0, null, 1],
            width: ["100%", null, "50%"],
            ...getProps(videoEmbedUrl, type),
          }}
        >
          {videoEmbedUrl ? (
            <ResponsiveIframe
              url={videoEmbedUrl}
              embedType={"Youtube"}
              description={videoEmbedTitle}
            />
          ) : (
            <GatsbyImage
              image={image.gatsbyImageData}
              sx={{
                borderTopRightRadius: imageBorderRadius(type),
                overflow: "hidden",
                minHeight: [400, null, "auto"],
                height: ["auto", null, "100%"],
                zIndex: 1,
              }}
            />
          )}
        </Box>
      );
    case Types.TIMELESSIMAGELEFT:
    case Types.TIMELESSIMAGERIGHT:
      return (
        <Box
          sx={{
            flex: "none",
            order: [1, 1, 0],
            width: ["100%", null, "50%"],
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            sx={{
              overflow: "hidden",
              height: ["12rem", "14rem", "18rem", "18rem"],
              zIndex: 1,
            }}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            display: type === Types.THEME ? ["none", null, "block"] : "block",
            flex: "none",
            order: 0,
            width: ["100%", null, "50%"],
            pl: type === Types.THEME ? [0, null, 3] : null,
            ...getProps(videoEmbedUrl, type),
          }}
        >
          {videoEmbedUrl ? (
            <ResponsiveIframe
              url={videoEmbedUrl}
              embedType={"Youtube"}
              description={videoEmbedTitle}
            />
          ) : (
            <GatsbyImage
              image={image.gatsbyImageData}
              sx={{
                borderTopLeftRadius: imageBorderRadius(type),
                overflow: "hidden",
                minHeight: [400, null, "auto"],
                height: ["auto", null, "100%"],
                zIndex: 1,
              }}
            />
          )}
        </Box>
      );
  }
};

const Hero = ({
  title,
  type,
  image,
  description,
  anchor,
  videoEmbedUrl,
  videoEmbedTitle,
  ...rest
}) => {
  return (
    <Flex
      sx={{
        position: "relative",
        flexDirection: ["column", null, "row"],
        scrollMarginTop: "104px",
      }}
      id={anchor}
    >
      <HeroText title={title} description={description} type={type} {...rest} />
      <HeroMedia
        image={image}
        type={type}
        videoEmbedUrl={videoEmbedUrl}
        videoEmbedTitle={videoEmbedTitle}
        title={title}
      />
    </Flex>
  );
};

export default Hero;
