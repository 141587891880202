/** @jsx jsx */
import { jsx } from "theme-ui";

const ResponsiveIframeAspectRatios = {
  Youtube: {
    ratio: "56.25%", // 16:9
  },
  Surveypal: {
    height: "1920px"
  }
};

const ResponsiveIframe = ({ url, embedType, description }) => {
  const padding = ResponsiveIframeAspectRatios[embedType] && { paddingTop: ResponsiveIframeAspectRatios[embedType].ratio };
  const height = ResponsiveIframeAspectRatios[embedType] && { height: ResponsiveIframeAspectRatios[embedType].height };
  return (
    <div
      sx={{
        position: "relative",
        overflow: "hidden",
        ...padding,
        ...height
      }}
    >
      <iframe
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={url}
          title={description}
          scrolling="auto" 
          frameBorder="0" 
          allowtransparency="true" 
          allowFullscreen="true"
          />
      </div>
  );
};

export default ResponsiveIframe;