/** @jsx jsx */
import { jsx } from "theme-ui";

const EmbeddedScript = ({ data, anchor }) => {
  const { scripts } = data;
  if (!Array.isArray(scripts)) return null;

  const scriptTags = scripts.map(script => {
    const { id, src } = script;
    return `<script type="text/javascript" src="${src}" ${ id ? `id="${id}"` : '' }></script>`;
  });

  return <div id={anchor} dangerouslySetInnerHTML={{ __html: scriptTags.join('') }} sx={{ scrollMarginTop: "104px" }}></div>;
};

export default EmbeddedScript;
