/** @jsx jsx */
import { jsx, Container, Themed, Flex } from "theme-ui";
import RichText2 from "./RichText2";
import colors from "../gatsby-plugin-theme-ui/colors";
import AnchorLink from "react-anchor-link-smooth-scroll";

const LiftUpColor = ({
  title,
  locale,
  color,
  content,
  language,
  anchorLinkTarget,
  hideTitle,
  ...props
}) => {
  let background, textColor;
  switch (color) {
    case "lightGrey":
      background = colors.muted;
      textColor = colors.black;
      break;
    case "lightGreen":
      background = colors.lightGreen;
      textColor = colors.black;
      break;
    case "green":
      background = colors.primary;
      textColor = colors.background;
      break;
    case "error":
      background = colors.error;
      textColor = colors.background;
      break;
    case "blue":
      background = colors.blue;
      textColor = colors.background;
      break;
    default:
      background = colors.muted;
  }
  if (!props.type) {
    return (
      <Container
        sx={{
          width: "100%",
          backgroundColor: background,
          color: textColor,
          paddingTop: 20,
          paddingBottom: 15,
          breakInside: "avoid",
          "a:link, a:hover, a:visited, a:active": {
            color: textColor,
            textDecoration: "underline",
          },
          "a:hover": {
            textDecoration: "none",
          },
        }}
      >
        {hideTitle ? (
          ""
        ) : (
          <Themed.h4 lang={language} sx={{ marginBlockStart: "0.5em" }}>
            {title}
          </Themed.h4>
        )}
        <RichText2 content={content} locale={locale} language={language} />
      </Container>
    );
  } else if (props.type === "embedded" && anchorLinkTarget) {
    return (
      <AnchorLink
        href={`#${anchorLinkTarget}`}
        offset="100"
        style={{
          textDecoration: "none",
          color: "black",
          breakInside: "avoid",
        }}
      >
        <Container
          sx={{
            width: "100%",
            minHeight: 156,
            marginBottom: 20,
            backgroundColor: background,
            color: textColor,
            textAlign: "center",
            textAlignLast: "center",
            breakInside: "avoid",
          }}
        >
          <div
            style={{
              maxWidth: 150,
              margin: "0 auto",
              pageBreakInside: "avoid !important",
            }}
          >
            {hideTitle ? (
              ""
            ) : (
              <Themed.h6
                sx={{ margin: 0, paddingTop: 1, wordWrap: "break-word" }}
                lang={language}
              >
                {title}
              </Themed.h6>
            )}
            <RichText2 content={content} locale={locale} language={language} />
          </div>
        </Container>
      </AnchorLink>
    );
  } else if (props.type === "embedded" && !anchorLinkTarget) {
    return (
      <Container
        sx={{
          width: "100%",
          minHeight: 156,
          marginBottom: 20,
          backgroundColor: background,
          color: textColor,
          textAlign: "center",
          textAlignLast: "center",
          breakInside: "avoid",
        }}
      >
        <div style={{ maxWidth: 150, margin: "0 auto", breakInside: "avoid" }}>
          {hideTitle ? (
            ""
          ) : (
            <Themed.h6
              sx={{ margin: 0, paddingTop: 1, wordWrap: "break-word" }}
              lang={language}
            >
              {title}
            </Themed.h6>
          )}
          <RichText2 content={content} locale={locale} language={language} />
        </div>
      </Container>
    );
  }
};

export default LiftUpColor;
