/** @jsx jsx */
import { jsx, Box, Flex, Themed } from "theme-ui";
import LiftUpColor from "./LiftUpColor";

const COLUMN_VARIANTS = {
  3: ["repeat(2, 1fr)", null, null, "repeat(3, 1fr)"],
  4: ["repeat(2, 1fr)", null, null, "repeat(4, 1fr)"],
  6: ["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(6, 1fr)"],
};

const pdfSize = 700; // PDFwidth in pixels

const CoverageGrid = ({
  title,
  boxes,
  columns,
  hideTitle,
  locale,
  language,
  ...props
}) => {
  return (
    <Flex
      sx={{
        flexWrap: "wrap",
        width: "100%",
        breakInside: "avoid",
      }}
    >
      {boxes.map(function (item, i) {
        return (
          <Box
            key={i}
            sx={{
              width: [
                "50%",
                (1 / (columns && columns !== 6 ? columns : 3)) * 100 + "%",
                (1 / (columns && columns !== 6 ? columns : 4)) * 100 + "%",
              ],
              pr: [2, 3, 1, 3],
              "@media print": {
                width: (1 / (columns && columns !== 6 ? columns : 4)) * pdfSize,
              },
            }}
          >
            <LiftUpColor
              key={i}
              title={item.title}
              content={item.content}
              color={item.title}
              anchorLinkTarget={item.anchorLinkTarget}
              hidetitle={hideTitle}
              language={language}
              type="embedded"
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export default CoverageGrid;
