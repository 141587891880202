import { useStaticQuery, graphql } from "gatsby";

export const useAllContentByContentTopics = (
  selectedContentTopics,
  locale,
  primaryTopics
) => {
  const {
    allContentfulPage,
    allContentfulProductPage,
    allContentfulThemePage,
    allContentfulContentTopic,
  } = useStaticQuery(
    graphql`
      query allPages {
        allContentfulPage: allContentfulPage(filter: { hidden: { ne: true } }) {
          nodes {
            title
            contentful_id
            slug
            pageType
            updatedAt(locale: "fi-FI")
            node_locale
            contentTopics {
              title
              contentType {
                title
              }
            }
            internal {
              type
            }
            headerImage {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
            navigationIcon {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
            parentPage {
              ... on ContentfulPage {
                id
                slug
              }
              ... on ContentfulProductPage {
                id
                slug
              }
              ... on ContentfulThemePage {
                id
                slug
              }
            }
          }
        }
        allContentfulProductPage: allContentfulProductPage(
          filter: { hidden: { ne: true } }
        ) {
          nodes {
            title
            contentful_id
            slug
            pageType
            updatedAt(locale: "fi-FI")
            node_locale
            contentTopics {
              title
              contentType {
                title
              }
            }
            internal {
              type
            }
            navigationIcon {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
            parentPage {
              ... on ContentfulPage {
                id
                slug
              }
              ... on ContentfulThemePage {
                id
                slug
              }
            }
            hero {
              image {
                gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
                description
              }
            }
          }
        }
        allContentfulThemePage: allContentfulThemePage(
          filter: { hidden: { ne: true } }
        ) {
          nodes {
            title
            contentful_id
            slug
            pageType
            updatedAt(locale: "fi-FI")
            node_locale
            contentTopics {
              title
              contentType {
                title
              }
            }
            internal {
              type
            }
            navigationIcon {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
            hero {
              image {
                gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
                description
              }
            }
          }
        }
        allContentfulContentTopic: allContentfulContentTopic {
          nodes {
            title
          }
        }
      }
    `
  );

  var allPages = [
    ...allContentfulPage.nodes,
    ...allContentfulProductPage.nodes,
    ...allContentfulThemePage.nodes,
  ];

  const filterByNodeLocale = (items, nodeLocale) => {
    return items.filter((node) => node.node_locale === nodeLocale);
  };

  const filterByContentTag = (items, topics, topicCount) => {
    return items.filter((node) => {
      const topicsList = topicCount
        ? node.contentTopics && node.contentTopics.slice(0, topicCount)
        : node.contentTopics;

      return (
        topicsList &&
        topicsList.some((topic) => {
          return topics.some((tag) => {
            return tag.title === topic.title;
          });
        })
      );
    });
  };

  const allPagesByLocale = filterByNodeLocale(allPages, locale);
  const pagesByContentTopics = filterByContentTag(
    allPagesByLocale,
    selectedContentTopics,
    primaryTopics
  );

  const sortByDate = (items) => {
    return items.slice().sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  };

  return {
    matchingContent: sortByDate(pagesByContentTopics),
  };
};
