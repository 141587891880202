/** @jsx jsx */
import { jsx, Grid, Box, Themed } from "theme-ui";
import React, { useState, useEffect, useRef } from "react";
import { useAllContentfulOffice } from "../hooks/useAllContentfulOffices";
import RichText2 from "./RichText2";
import { ArrowDown } from "./Icon";
import { motion, AnimatePresence } from "framer-motion";
import { isBrowser, getPageHash, generateAncorHash } from "../utils/utils.js";

const OfficeCard = ({
  title,
  content,
  notification,
  locale,
  language,
  pageType,
  companyCustomerContent,
  ...props
}) => {
  const anchor = generateAncorHash(title);
  const hash = getPageHash();
  const [expanded, setExpanded] = useState(false);
  const gridRef = useRef();
  useEffect(() => {
    if (hash === anchor) {
      setExpanded(true);
      // hack for gatsby route change
      setTimeout(() => {
        if (isBrowser && window && gridRef && gridRef.current) {
          window.scrollTo(0, gridRef.current.offsetTop - 100);
        }
      }, 100);
    }
  }, [hash, gridRef, anchor]);

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
        pt: 3,
        pb: 2,
        borderColor: "mutedGrey",
        borderBottom: "solid .5px",
      }}
      ref={gridRef}
      {...props}
    >
      <Grid
        gap={2}
        columns={[2, "30px auto"]}
        sx={{ cursor: "pointer" }}
        onClick={() => setExpanded(expanded ? false : true)}
      >
        <motion.div
          style={{
            width: 24,
            height: 24,
            transformOrigin: "center center",
          }}
          animate={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <ArrowDown />
        </motion.div>

        <Themed.h4
          as={motion.header}
          initial={false}
          sx={{
            ":not(:first-child)": { mt: [0], mb: 0 },
            fontWeight: "normal",
          }}
        >
          {title}
        </Themed.h4>
      </Grid>

      <AnimatePresence initial={false}>
        <motion.section
          style={{ display: expanded ? "block" : "none" }}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.3, ease: [0.04, 0.6, 0.2, 1] }}
        >
          <motion.div
            sx={{ px: 5 }}
            variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
            transition={{ duration: 0.15 }}
          >
            {notification && (
              <Themed.h4
                sx={{
                  p: 1,
                  bg: "notice",
                  ":not(:first-child)": { mt: [0], mb: 0 },
                }}
              >
                {notification}
              </Themed.h4>
            )}
            <RichText2
              sx={{
                px: 3,
                pb: 3,
                "*:first-of-type": { mt: 0 },
                "*:last-of-type": { mb: 0 },
              }}
              content={content}
              locale={locale}
              language={language}
            />
            {pageType === "Company customer" && (
              <RichText2
                sx={{
                  px: 3,
                  pb: 3,
                  "*:first-of-type": { mt: 0 },
                  "*:last-of-type": { mb: 0 },
                }}
                content={companyCustomerContent}
                locale={locale}
                language={language}
              />
            )}
          </motion.div>
        </motion.section>
      </AnimatePresence>
    </Box>
  );
};

const RegionCardList = ({ data, locale, language, anchor }) => {
  const allOffice = useAllContentfulOffice();
  let offices = allOffice[language];
  const regions = data.regions;
  const pageType = data.pageType;

  // Loop trough offices and map their region to regions Title
  const regionList = regions.map((region) => {
    const officeList = offices.filter(
      (office) => office.node.region === region.Region
    );
    return {
      offices: officeList,
      region: region.Title,
      regionNotification: region.Notification,
    };
  });

  return (
    <Box id={anchor} sx={{ scrollMarginTop: "104px" }}>
      {regionList.map(({ region, offices, regionNotification }, index) => (
        <Box key={`region-item-${index}`}>
          {region && (
            <Themed.h3
              sx={{
                mt: 0,
                mb: 0,
              }}
            >
              {region}
            </Themed.h3>
          )}
          {regionNotification && (
            <Themed.h4
              sx={{
                p: 1,
                bg: "notice",
                ":not(:first-child)": { mt: [0], mb: 0 },
              }}
            >
              {regionNotification}
            </Themed.h4>
          )}
          {offices.map(({ node: office }) => (
            <OfficeCard
              key={office.id}
              title={office.title}
              notification={office.notification}
              content={office.content.raw}
              pageType={pageType}
              companyCustomerContent={
                office.companyCustomerContent &&
                office.companyCustomerContent.raw
              }
              locale={locale}
              language={language}
              {...office}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default RegionCardList;
