import { useStaticQuery, graphql } from "gatsby";

const ARTICLE_MAX_COUNT = 10;

export const useLatestContentfulArticles = () => {
  const {
    articlesFI,
    importantArticlesFI,
    articlesSV,
    importantArticlesSV,
    articlesEN,
    importantArticlesEN,
  } = useStaticQuery(
    graphql`
      query latestArticles {
        articlesFI: allContentfulContentHubPage(
          limit: 10
          sort: { fields: publishDate, order: DESC }
          filter: { node_locale: { eq: "fi-FI" }, hidden: { ne: true } }
        ) {
          edges {
            node {
              id
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        importantArticlesFI: allContentfulContentHubPage(
          limit: 3
          sort: { fields: publishDate, order: DESC }
          filter: {
            node_locale: { eq: "fi-FI" }
            hidden: { ne: true }
            important: { eq: true }
          }
        ) {
          edges {
            node {
              id
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        articlesSV: allContentfulContentHubPage(
          limit: 10
          sort: { fields: publishDate, order: DESC }
          filter: { node_locale: { eq: "sv-SE" }, hidden: { ne: true } }
        ) {
          edges {
            node {
              id
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        importantArticlesSV: allContentfulContentHubPage(
          limit: 3
          sort: { fields: publishDate, order: DESC }
          filter: {
            node_locale: { eq: "sv-SE" }
            hidden: { ne: true }
            important: { eq: true }
          }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        articlesEN: allContentfulContentHubPage(
          limit: 10
          sort: { fields: publishDate, order: DESC }
          filter: { node_locale: { eq: "en-US" }, hidden: { ne: true } }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        importantArticlesEN: allContentfulContentHubPage(
          limit: 3
          sort: { fields: publishDate, order: DESC }
          filter: {
            node_locale: { eq: "en-US" }
            hidden: { ne: true }
            important: { eq: true }
          }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
        importantArticlesEN: allContentfulContentHubPage(
          limit: 3
          sort: { fields: publishDate, order: DESC }
          filter: {
            node_locale: { eq: "en-US" }
            hidden: { ne: true }
            important: { eq: true }
          }
        ) {
          edges {
            node {
              id
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
              parentPage {
                slug
              }
            }
          }
        }
      }
    `
  );

  const filterDuplicates = (importantArticles, articles) => {
    return [
      ...new Map(
        [...importantArticles, ...articles].map((edge) => [edge.node.id, edge])
      ).values(),
    ];
  };

  // Separate queries might have duplicates so filter those out
  return {
    fi: filterDuplicates(importantArticlesFI.edges, articlesFI.edges).slice(
      0,
      ARTICLE_MAX_COUNT
    ),
    sv: filterDuplicates(importantArticlesSV.edges, articlesSV.edges).slice(
      0,
      ARTICLE_MAX_COUNT
    ),
    en: filterDuplicates(importantArticlesEN.edges, articlesEN.edges).slice(
      0,
      ARTICLE_MAX_COUNT
    ),
  };
};
