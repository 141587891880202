/** @jsx jsx */
import { jsx, Box, Themed } from "theme-ui";
import { useContext } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import Link from "./Link";
import { useMostRecentContentHubPages } from "../hooks/useMostRecentContentHubPages";

const MostRecentContentHubPages = ({ locale }) => {
  const { translate } = useContext(LocalizationContext);
  const recentPages = useMostRecentContentHubPages();
  const localizedPages = recentPages.find((item) => item.locale === locale)[
    "items"
  ]; // TODO: Fixme!

  const renderSingleItem = ({ title, slug }, index) => {
    return (
      <Box key={`article-title-${index}`}>
        <Themed.h3
          sx={{ marginBottom: 0, fontWeight: "normal" }}
          id={`article-title-${index}`}
        >
          {title}
        </Themed.h3>
        <Link
          target={slug}
          aria-labelledby={`article-title-${index}`}
          showArrow
          sx={{ fontWeight: "bold" }}
        >
          {translate("contentHub.readMore")}
        </Link>
      </Box>
    );
  };

  return (
    <Box sx={{ bg: "muted", p: [3, 4] }}>
      <Themed.h2 sx={{ mt: 0 }} tabIndex={0}>
        {translate("contentHub.latestContent")}
      </Themed.h2>
      {localizedPages.map((item, index) => renderSingleItem(item, index))}
    </Box>
  );
};

export default MostRecentContentHubPages;
