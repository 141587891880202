/** @jsx jsx */
import { jsx, Box, Flex, Themed } from "theme-ui";
import Link from "./Link";
import { useAllHeroTargets } from "../hooks/useAllHeroTargets";
import { generateAnalyticsId, sendLinkEvent } from "../utils/analytics";
const defaultNavigationImage = "/navigationIcon.png";

const COLUMN_VARIANTS = {
  3: ["repeat(2, 1fr)", null, null, "repeat(3, 1fr)"],
  4: ["repeat(2, 1fr)", null, null, "repeat(4, 1fr)"],
  6: ["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(6, 1fr)"],
};

const findHeroTarget = (cid, slug, locale, nodes) => {
  if (!cid) return slug;
  // remove custom id prefixes
  if (cid.startsWith("s_c")) cid = cid.substring(3);
  if (cid.startsWith("s_")) cid = cid.substring(2);
  return nodes.reduce((slug, node) => {
    const target = node.node;
    if (target.product_page) {
      let page = target.product_page.find(
        (p) => p.contentful_id === cid && p.node_locale === locale
      );
      if (page && target.target) return target.target;
    }
    if (target.theme_page) {
      let page = target.theme_page.find(
        (p) => p.contentful_id === cid && p.node_locale === locale
      );
      if (page && target.target) return target.target;
    }
    if (target.frontpage) {
      let page = target.frontpage.find(
        (p) => p.contentful_id === cid && p.node_locale === locale
      );
      if (page && target.target) return target.target;
    }
    return slug;
  }, slug);
};

const LiftUpLinks = ({
  title,
  links,
  columns,
  typeoflink,
  hideTitle = false,
  locale,
  analyticsId,
  contentful_id,
}) => {
  const heroTargets = useAllHeroTargets();
  if (!links || !title) return;
  const validLinks = links.filter((link) => link !== null);

  return (
    <Box sx={{ mr: [-2, -3], mb: [-2, -3] }}>
      {!hideTitle && <Themed.h2>{title}</Themed.h2>}

      <Flex
        sx={{
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {validLinks.map(
          (
            {
              navigationTitle,
              hideLinkTitle,
              navigationIcon,
              slug,
              hero,
              cid,
              __typename,
              mediaAssetfile,
              url,
            },
            index
          ) => {
            if (!navigationTitle) return null;

            if (__typename === "ContentfulLiftUpLinkAsset") {
              slug = "";
              if (url) {
                slug = url.trim();
              } else if (mediaAssetfile && mediaAssetfile.file) {
                slug = mediaAssetfile.file.url;
              }
            }

            if (typeoflink === "Use referencing CTA url") {
              if (hero) {
                slug = hero; // old implementation
              } else {
                slug = findHeroTarget(cid, slug, locale, heroTargets);
              }
            }

            const dataAnalyticsId = generateAnalyticsId(
              analyticsId,
              contentful_id,
              slug ? slug : url
            );

            const backgroundImage =
              (navigationIcon &&
                `url(
            ${
              navigationIcon.fluid
                ? navigationIcon.fluid.src
                : navigationIcon.file.url
            }
            )`) ||
              `url(${defaultNavigationImage})`;
            return (
              <Box
                key={index}
                sx={{
                  width: [
                    "50%",
                    (1 / (columns && columns !== 6 ? columns : 3)) * 100 + "%",
                    (1 / (columns && columns !== 6 ? columns : 4)) * 100 + "%",
                    (1 / (columns ? columns : 6)) * 100 + "%",
                  ],
                  pr: [2, 3],
                  pb: [2, 3],
                }}
                onClick={() => sendLinkEvent(dataAnalyticsId)}
              >
                <Link
                  target={slug}
                  parentType="liftUpLinks"
                  key={index}
                  sx={{
                    position: "relative",
                    display: "block",
                    backgroundColor: "muted",
                    pb: "100%",
                    overflow: "hidden",
                    ":hover > div": {
                      transform: "scale(1.05) translateY(-2.5%)",
                    },
                  }}
                  aria-label={hideLinkTitle ? navigationTitle : ""}
                >
                  {!hideLinkTitle && (
                    <span
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        top: 2,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        color: "text",
                        fontSize: [1, 2],
                        lineHeight: "heading",
                        px: 1,
                      }}
                    >
                      {navigationTitle}
                    </span>
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundImage,
                      backgroundSize: "cover",
                      backgroundPosition: "center bottom",
                      transition: "all .15s ease",
                    }}
                  />
                </Link>
              </Box>
            );
          }
        )}
      </Flex>
    </Box>
  );
};

export default LiftUpLinks;
