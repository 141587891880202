/** @jsx jsx */
import { useState, useRef, useEffect } from "react";
import { jsx, Flex, Themed, Box } from "theme-ui";
import { CaretIcon, UmbrellaIcon, ClipboardIcon } from "./Icon";
import colors from "../gatsby-plugin-theme-ui/colors";
import Link from "./Link";
import {
  sendInsuranceDropdownEvent,
  generateAnalyticsId,
  sendDamageReportEvent,
} from "../utils/analytics";

const DropdownLinkList = ({ data, language, anchor }) => {
  const { title, placeholder, options, theme, icon, analyticsEvent } = data;
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef();
  const menuRef = useRef(null);

  useEffect(() => {
    if (showMenu) {
      menuRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const toggleDropdown = (e) => {
    setShowMenu(!showMenu);
    if (inputRef.current && !showMenu) {
      inputRef.current.focus();
    }
  };

  const selectOption = (option) => {
    setShowMenu(!showMenu);
    if (analyticsEvent && analyticsEvent === "insurance") {
      sendInsuranceDropdownEvent(option.label, option.value, language);
    }
    if (analyticsEvent && analyticsEvent === "accident") {
      const analyticsId = generateAnalyticsId(
        option.analyticsId,
        null,
        option.value
      );
      sendDamageReportEvent(analyticsId);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      setShowMenu(!showMenu);
    }
    if (e.key === "Tab" && e.shiftKey) {
      setShowMenu(false);
    }
  };

  const handleOptionSelectionKeyDown = (e, option) => {
    if (
      (e.key === "Tab" &&
        !e.shiftKey &&
        option === options[options.length - 1]) ||
      (e.key === "Tab" && e.shiftKey && option === options[0])
    ) {
      setShowMenu(false);
      inputRef.current.focus();
    }
  };

  return (
    <Flex
      ref={inputRef}
      role="navigation"
      id={anchor}
      aria-label={title}
      sx={{
        position: "relative",
        bg: theme && theme === "brand" ? "primary" : "muted",
        p: [3, 4],
        flexDirection: "column",
      }}
    >
      <Themed.h3
        sx={{
          color: theme && theme === "brand" ? "white" : "text",
          mt: 0,
          mb: 4,
        }}
      >
        {title}
      </Themed.h3>
      <Flex
        onClick={toggleDropdown}
        role="button"
        aria-haspopup="true"
        aria-expanded={showMenu ? "true" : "false"}
        onKeyDown={handleInputKeyDown}
        tabIndex={0}
        sx={{
          px: 2,
          py: 1,
          alignItems: "center",
          justifyContent: "space-between",
          userSelect: "none",
          background: "white",
          color: "text",
          cursor: "pointer",
        }}
      >
        {icon && icon === "umbrella" && (
          <UmbrellaIcon
            fill={colors.darkGrey}
            sx={{
              alignSelf: "center",
              pointerEvents: "none",
              transform: "translateX(-2px)",
              transformOrigin: "center",
            }}
            aria-hidden="true"
          />
        )}
        {icon && icon === "clipboard" && (
          <ClipboardIcon
            fill={colors.darkGrey}
            sx={{
              alignSelf: "center",
              pointerEvents: "none",
              transform: "translateX(-2px)",
              transformOrigin: "center",
            }}
            aria-hidden="true"
          />
        )}
        <Box
          sx={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
          aria-label={placeholder}
        >
          {placeholder}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", pl: 2, pb: 1 }}>
          <CaretIcon
            sx={{
              color: "text",
              alignSelf: "center",
              pointerEvents: "none",
              transform: "scale(2) ",
              transformOrigin: "top right",
            }}
            aria-hidden="true"
          />
        </Box>
      </Flex>
      {showMenu && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            as="ul"
            ref={menuRef}
            sx={{
              position: "absolute",
              transform: "translateY(0.1rem)",
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "lightGrey",
              overflow: "auto",
              maxHeight: "25rem",
              background: "white",
              zIndex: 10,
              pl: 0,
            }}
          >
            {options.map((option) => {
              return (
                <Flex
                  as="li"
                  key={option.label}
                  sx={{
                    px: 2,
                    py: 1,
                    listStyleType: "none",
                  }}
                >
                  <Link
                    target={option.value}
                    onKeyDown={(e) => handleOptionSelectionKeyDown(e, option)}
                    onClick={() => selectOption(option)}
                    sx={{
                      flex: "auto"
                    }}
                  >
                    {option.label}
                  </Link>
                </Flex>
              );
            })}
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export default DropdownLinkList;
