/** @jsx jsx */
import { jsx, Container, Themed, Box, Flex, Button } from "theme-ui";
import { ExternalLinkIcon } from "./Icon";
import { LocalizationContext } from "../context/LocalizationContext";
import React, { useEffect, useState, useContext } from "react";
import { getLifePrice, getPairLifePrice } from "../utils/api";
import { makeStyles } from "@material-ui/core/styles";
import { SelectField, CheckBox } from "./CalculatorComponents";
import { keyframes } from "@emotion/react";

// Set up contstants used in calculation
const date = new Date();

// 75 years is the maximum age for new policies
const maxBirthYear = date.getFullYear() - 75;
// 18 years if the minimum age for new policies
const minBirthYear = date.getFullYear() - 17;
// 60 years is the maximum age for permanent disability cover
const maxBirthYearForDisability = date.getFullYear() - 60;
// 59 years is the maximum age for serious illness cover
const maxBirthYearForSeriousIllness = date.getFullYear() - 59;

// Generate values for dropdowns
const years = [];
for (let i = maxBirthYear; i < minBirthYear; i++) {
  years.push(i);
}

const generateSums = (min, max, step) => {
  let generatedSums = [];
  for (let i = min; i <= max; i = i + step) {
    generatedSums.push(i);
  }
  return generatedSums;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    textAlign: "center",
    width: "100%",
  },
}));

export default function LifeInsuranceCalculator(props) {
  const classes = useStyles();
  const { translate } = useContext(LocalizationContext);
  const { language } = props;
  const { link } = props.data;

  const sums = generateSums(20000, 200000, 10000);
  const disabilitySums = generateSums(20000, 200000, 10000);
  disabilitySums.unshift(
    translate("lifeInsuranceCalculator.noCoverage", null, language)
  );
  const seriousIllnessSums = generateSums(20000, 100000, 10000);
  seriousIllnessSums.unshift(
    translate("lifeInsuranceCalculator.noCoverage", null, language)
  );

  // Calculator state object
  const [calculatorValues, setCalculatorValues] = useState({
    birthYear: 1985,
    secondInsuredPerson: false,
    secondPersonBirthYear: 1985,
    compensationAmount: 100000,
    disabilityCompensationAmount: translate(
      "lifeInsuranceCalculator.noCoverage",
      null,
      language
    ),
    secondPersonDisabilityCompensationAmount: translate(
      "lifeInsuranceCalculator.noCoverage",
      null,
      language
    ),
    seriousIllnessCompensationAmount: translate(
      "lifeInsuranceCalculator.noCoverage",
      null,
      language
    ),
    secondPersonSeriousIllnessCompensationAmount: translate(
      "lifeInsuranceCalculator.noCoverage",
      null,
      language
    ),
    isSmoker: false,
    secondPersonIsSmoker: false,
  });

  // Birth year handler
  const handleBirthYearChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      birthYear: event.target.value,
    }));
    if (event.target.value < maxBirthYearForDisability) {
      setCalculatorValues((calculatorValues) => ({
        ...calculatorValues,
        disabilityCompensationAmount: translate(
          "lifeInsuranceCalculator.noCoverage",
          null,
          language
        ),
      }));
    }
    if (event.target.value < maxBirthYearForSeriousIllness) {
      setCalculatorValues((calculatorValues) => ({
        ...calculatorValues,
        seriousIllnessCompensationAmount: translate(
          "lifeInsuranceCalculator.noCoverage",
          null,
          language
        ),
      }));
    }
  };

  // Second person toggle year handler
  const toggleSecondInsuredPerson = () => {
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      secondInsuredPerson: !secondInsuredPerson,
    }));
  };

  // Second person birth year handler
  const handleSecondPersonBirthYearChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      secondPersonBirthYear: event.target.value,
    }));
    if (event.target.value < maxBirthYearForDisability) {
      setCalculatorValues((calculatorValues) => ({
        ...calculatorValues,
        secondPersonDisabilityCompensationAmount: translate(
          "lifeInsuranceCalculator.noCoverage",
          null,
          language
        ),
      }));
    }
    if (event.target.value < maxBirthYearForSeriousIllness) {
      setCalculatorValues((calculatorValues) => ({
        ...calculatorValues,
        secondPersonSeriousIllnessCompensationAmount: translate(
          "lifeInsuranceCalculator.noCoverage",
          null,
          language
        ),
      }));
    }
  };

  // Compensation amount handler
  const handleCompensationSumChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      compensationAmount: event.target.value,
    }));
  };

  // disability compensation amount handler
  const handleDisabilityCompensationSumChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      disabilityCompensationAmount: event.target.value,
    }));
  };

  // second person disability compensation amount handler
  const handleSecondPersonDisabilityCompensationSumChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      secondPersonDisabilityCompensationAmount: event.target.value,
    }));
  };

  // serious illness compensation amount handler
  const handleSeriousIllnessCompensationSumChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      seriousIllnessCompensationAmount: event.target.value,
    }));
  };

  // Second person serious illness compensation amount handler
  const handleSecondPersonSeriousIllnessSumChange = (event) => {
    event.preventDefault();
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      secondPersonSeriousIllnessCompensationAmount: event.target.value,
    }));
  };

  // Is smoker toggle handler
  const toggleIsSmoker = () => {
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      isSmoker: !isSmoker,
    }));
  };

  // Second person is smoker toggle handler
  const toggleSecondPersonIsSmoker = () => {
    setCalculatorValues((calculatorValues) => ({
      ...calculatorValues,
      secondPersonIsSmoker: !secondPersonIsSmoker,
    }));
  };

  const {
    birthYear,
    secondInsuredPerson,
    secondPersonBirthYear,
    compensationAmount,
    disabilityCompensationAmount,
    secondPersonDisabilityCompensationAmount,
    seriousIllnessCompensationAmount,
    secondPersonSeriousIllnessCompensationAmount,
    isSmoker,
    secondPersonIsSmoker,
  } = calculatorValues;

  const [price, setPrices] = useState(null);

  useEffect(() => {
    getLifePrice(
      compensationAmount,
      disabilityCompensationAmount,
      seriousIllnessCompensationAmount,
      isSmoker,
      birthYear
    ).then((data) => setPrices(data));
  }, [
    birthYear,
    compensationAmount,
    disabilityCompensationAmount,
    seriousIllnessCompensationAmount,
    isSmoker,
  ]);

  const [priceSecondPerson, setPriceSecondPerson] = useState(null);

  useEffect(() => {
    if (secondInsuredPerson) {
      getLifePrice(
        compensationAmount,
        secondPersonDisabilityCompensationAmount,
        secondPersonSeriousIllnessCompensationAmount,
        secondPersonIsSmoker,
        secondPersonBirthYear
      ).then((data) => setPriceSecondPerson(data));
    }
  }, [
    secondInsuredPerson,
    secondPersonBirthYear,
    compensationAmount,
    secondPersonDisabilityCompensationAmount,
    secondPersonSeriousIllnessCompensationAmount,
    secondPersonIsSmoker,
  ]);

  const [pairLifePrice, setPairLifePrice] = useState(null);

  useEffect(() => {
    if (secondInsuredPerson) {
      getPairLifePrice(
        compensationAmount,
        birthYear,
        secondPersonBirthYear
      ).then((data) => setPairLifePrice(data));
    }
  }, [
    secondInsuredPerson,
    birthYear,
    secondPersonBirthYear,
    compensationAmount,
  ]);

  const [displayedPrice, setDisplayedPrice] = useState({
    discounted: 0,
    normal: 0,
  });

  const calculatePrice = (priceType, priceObject, excludedProp) => {
    let sum = 0;
    for (let prop in priceObject) {
      if (prop !== excludedProp) {
        sum += priceObject[prop][priceType];
      }
    }
    return sum;
  };

  const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  useEffect(() => {
    if (secondInsuredPerson) {
      setDisplayedPrice({
        discounted: (
          calculatePrice("discountedMonthlyPrice", price, "lifeProtection") +
          calculatePrice(
            "discountedMonthlyPrice",
            priceSecondPerson,
            "lifeProtection"
          ) +
          calculatePrice("discountedMonthlyPrice", pairLifePrice)
        ).toFixed(2),
        normal: (
          calculatePrice("monthlyPrice", price, "lifeProtection") +
          calculatePrice("monthlyPrice", priceSecondPerson, "lifeProtection") +
          calculatePrice("monthlyPrice", pairLifePrice)
        ).toFixed(2),
      });
    } else {
      setDisplayedPrice({
        discounted: calculatePrice("discountedMonthlyPrice", price).toFixed(2),
        normal: calculatePrice("monthlyPrice", price).toFixed(2),
      });
    }
  }, [price, priceSecondPerson, pairLifePrice, secondInsuredPerson]);

  // Determine if we can show permament disability product, depending on age
  const displayDisability = (year, state, handler) => {
    if (year >= maxBirthYearForDisability) {
      return (
        <React.Fragment>
          <Themed.p sx={{ fontSize: ["0.70em", "0.8em", "0.8em", "0.8em"] }}>
            {translate(
              "lifeInsuranceCalculator.permanentDisability",
              null,
              language
            )}
          </Themed.p>
          <SelectField
            values={disabilitySums}
            label={translate(
              "lifeInsuranceCalculator.coverageAmount",
              null,
              language
            )}
            stateValue={state}
            onChange={handler}
            className={classes.formControl}
          />
        </React.Fragment>
      );
    } else {
      return (
        <Themed.p sx={{ fontSize: "0.8em" }}>
          {translate(
            "lifeInsuranceCalculator.permanentDisabilityNotAvailable",
            null,
            language
          )}
        </Themed.p>
      );
    }
  };

  const isNumber = (value) => {
    if (typeof value === "number") {
      return true;
    } else {
      return false;
    }
  };

  // Business customers can bypass the default HF calculator landing page and proceed directly to buy
  // Private customers must be directed to the default page to fill out a survey
  const constructRedirectLink = (link) => {
    let constructedLink = link;
    if (
      constructedLink.includes(
        "https://henki.fennia.fi/henkivakuutus/yrityksille/osta"
      )
    ) {
      constructedLink = `${constructedLink}&syntymaVuosi=${calculatorValues.birthYear}&korvausSumma=${calculatorValues.compensationAmount}`;
      if (typeof calculatorValues.disabilityCompensationAmount === "number") {
        constructedLink +=
          "&tyoKyvyttomyydenKorvausSumma=" +
          calculatorValues.disabilityCompensationAmount;
      }
      if (
        typeof calculatorValues.seriousIllnessCompensationAmount === "number"
      ) {
        constructedLink +=
          "&vstKorvausSumma=" +
          calculatorValues.seriousIllnessCompensationAmount +
          "&tupakoi=" +
          calculatorValues.isSmoker;
      }
      if (calculatorValues.secondInsuredPerson) {
        constructedLink +=
          "&parinSyntymaVuosi=" + calculatorValues.secondPersonBirthYear;
        if (
          typeof calculatorValues.secondPersonDisabilityCompensationAmount ===
          "number"
        ) {
          constructedLink +=
            "&parinTyoKyvyttomyydenKorvausSumma=" +
            calculatorValues.secondPersonDisabilityCompensationAmount;
        }
        if (
          typeof calculatorValues.secondPersonSeriousIllnessCompensationAmount ===
          "number"
        ) {
          constructedLink +=
            "&parinVstKorvausSumma=" +
            calculatorValues.secondPersonSeriousIllnessCompensationAmount +
            "&pariTupakoi=" +
            calculatorValues.secondPersonIsSmoker;
        }
      }
      return constructedLink;
    } else {
      return constructedLink;
    }
  };

  const redirectDefault = (e) => {
    e.preventDefault();
    const target = constructRedirectLink(link);
    window.open(target, "_blank");
  };

  // Determine if we can display serious illness product, depending on age
  const displaySeriousIllness = (
    year,
    state,
    handler,
    smokerState,
    smokerHandler
  ) => {
    if (year >= maxBirthYearForSeriousIllness) {
      return (
        <React.Fragment>
          <Themed.p sx={{ fontSize: ["0.7em", "0.8em", "0.8em", "0.8em"] }}>
            {translate(
              "lifeInsuranceCalculator.seriousIllness",
              null,
              language
            )}
          </Themed.p>
          <SelectField
            values={seriousIllnessSums}
            label={translate(
              "lifeInsuranceCalculator.coverageAmount",
              null,
              language
            )}
            stateValue={state}
            onChange={handler}
            className={classes.formControl}
          />
          {isNumber(state) ? (
            <CheckBox
              stateValue={smokerState}
              onChange={smokerHandler}
              name={"smoking"}
              label={translate(
                "lifeInsuranceCalculator.isSmoking",
                null,
                language
              )}
              tooltip={translate(
                "lifeInsuranceCalculator.smokingTooltip",
                null,
                language
              )}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return (
        <Themed.p sx={{ fontSize: "0.8em" }}>
          {translate(
            "lifeInsuranceCalculator.seriousIllnessNotAvailable",
            null,
            language
          )}
        </Themed.p>
      );
    }
  };

  return (
    <Container
      sx={{
        boxSizing: "border-box",
        backgroundColor: "white",
        maxWidth: 430,
        minWidth: 360,
        px: [2, 3],
        my: 20,
        py: 4,
        boxShadow:
          "0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important",
        display: "grid",
        gridTemplate: " 50px 360px 110px 200px 80px / 1fr 1fr",
        gridColumnGap: "20px",
      }}
    >
      <Themed.h2
        sx={{
          textAlign: "center",
          marginTop: -8,
          gridArea: "1 / 1 / span 1 / -1",
        }}
      >
        {translate("lifeInsuranceCalculator.title", null, language)}
      </Themed.h2>
      <Flex
        sx={{ flexDirection: "column", gridArea: "2 / 1 / span 1 / span 1" }}
      >
        <SelectField
          values={years}
          label={translate("lifeInsuranceCalculator.birthYear", null, language)}
          stateValue={birthYear}
          onChange={handleBirthYearChange}
          className={classes.formControl}
        />
        {displayDisability(
          birthYear,
          disabilityCompensationAmount,
          handleDisabilityCompensationSumChange
        )}
        {displaySeriousIllness(
          birthYear,
          calculatorValues.seriousIllnessCompensationAmount,
          handleSeriousIllnessCompensationSumChange,
          calculatorValues.isSmoker,
          toggleIsSmoker
        )}
      </Flex>
      {secondInsuredPerson ? (
        <Themed.p
          sx={{
            position: "relative",
            top: 8,
            right: 10,
            textAlign: "right",
            color: "crimson",
            lineHeigth: "1.3em",
            fontSize: "0.9em",
            cursor: "pointer",
            gridArea: "1 / 2 / span 1 / -1",
          }}
          role="button"
          tabIndex="0"
          onClick={toggleSecondInsuredPerson}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              return toggleSecondInsuredPerson();
            }
          }}
        >
          X
        </Themed.p>
      ) : (
        ""
      )}
      <Flex
        sx={{ flexDirection: "column", gridArea: "2 / 2 / span 1 / span 1" }}
      >
        {secondInsuredPerson ? (
          <React.Fragment>
            <SelectField
              values={years}
              label={translate(
                "lifeInsuranceCalculator.birthYear",
                null,
                language
              )}
              stateValue={secondPersonBirthYear}
              onChange={handleSecondPersonBirthYearChange}
              className={classes.formControl}
            />
            {displayDisability(
              secondPersonBirthYear,
              secondPersonDisabilityCompensationAmount,
              handleSecondPersonDisabilityCompensationSumChange
            )}
            {displaySeriousIllness(
              secondPersonBirthYear,
              calculatorValues.secondPersonSeriousIllnessCompensationAmount,
              handleSecondPersonSeriousIllnessSumChange,
              calculatorValues.secondPersonIsSmoker,
              toggleSecondPersonIsSmoker
            )}
          </React.Fragment>
        ) : (
          <Box
            sx={{
              border: "1px dashed #c4c4c4",
              height: "100%",
              display: "flex",
              margin: "0 auto",
              padding: [1, 2, 3, 3],
              alignItems: "center",
            }}
          >
            <Themed.p
              sx={{
                textAlign: "center",
                color: "green",
                textDecoration: "underline",
                lineHeigth: "1.3em",
                fontSize: "0.9em",
                cursor: "pointer",
              }}
              onClick={toggleSecondInsuredPerson}
              tabIndex="0"
              role="button"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  return toggleSecondInsuredPerson();
                }
              }}
            >
              {translate("lifeInsuranceCalculator.pairLife", null, language)}
            </Themed.p>
          </Box>
        )}
      </Flex>
      <Box sx={{ gridArea: "3 / 1 / span 1 / -1" }}>
        <Themed.p id="lifeAmount">
          {translate(
            "lifeInsuranceCalculator.lifeInsuranceCoverageAmount",
            null,
            language
          )}
        </Themed.p>
        <SelectField
          values={sums}
          stateValue={compensationAmount}
          onChange={handleCompensationSumChange}
          aria-labelledby="lifeAmount"
          className={classes.formControl}
        />
      </Box>
      {price ? (
        <Box
          key={displayedPrice.discounted}
          sx={{
            gridArea: "4 / 1 / -1 / -1",
            width: "100%",
            animation: `2s ${fadeIn}`,
          }}
        >
          <Themed.p
            sx={{
              color: "#008a28",
              fontSize: "2.5em",
              fontWeight: "bolder",
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            {displayedPrice.discounted.replace(".", ",")}{" "}
            {translate("lifeInsuranceCalculator.euroPerMonth", null, language)}
          </Themed.p>

          <Themed.p
            sx={{
              color: "black",
              fontSize: "0.9em",
              textAlign: "center",
              marginBottom: -1,
            }}
          >
            {translate(
              "lifeInsuranceCalculator.discount",
              null,
              language
            ).replace(
              "${discountPercent}",
              price.lifeProtection.discountPercent
            )}
          </Themed.p>
          <Themed.p
            sx={{ color: "#008a28", fontSize: "0.9em", textAlign: "center" }}
          >
            {translate(
              "lifeInsuranceCalculator.normalPricePerMonth",
              null,
              language
            ).replace("${normal}", displayedPrice.normal.replace(".", ","))}
          </Themed.p>
        </Box>
      ) : (
        ""
      )}
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gridArea: "5 / 1 / -1 / span 2",
        }}
      >
        <Button
          variant="green"
          onClick={redirectDefault}
          sx={{ whiteSpace: "normal", textAlign: "left", cursor: "pointer" }}
        >
          {translate("lifeInsuranceCalculator.buy", null, language)}
          <ExternalLinkIcon sx={{ display: "inline", marginLeft: 10 }} />
        </Button>
      </Flex>
    </Container>
  );
}
