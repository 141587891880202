import { useStaticQuery, graphql } from "gatsby";

export const useAllHeroTargets = () => {
  const { allContentfulHero } = useStaticQuery(
    graphql`
      query AllContentfulHeroTargetsQuery {
        allContentfulHero {
          edges {
            node {
              id
              contentful_id
              __typename
              theme_page {
                contentful_id
                node_locale
                slug
              }
              frontpage {
                contentful_id
                node_locale
                slug
              }
              product_page {
                contentful_id
                node_locale
                slug
              }
              target
              node_locale
            }
          }
        }
      }
    `
  );
  return allContentfulHero.edges;
};
