/** @jsx jsx */
import { jsx, Flex, Box, Grid, Themed, Button } from "theme-ui";
import { useAllContentByContentTopics } from "../hooks/useAllContentByContentTopics";
import { useState, useContext } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import ContentTopicItem from "../components/ContentTopicItem";

const ShowMoreContent = ({ displayPages, next, handleShowMore, translate }) => {
  return (
    <Box>
      {next < displayPages?.length && (
        <Button
          variant="secondary"
          onClick={handleShowMore}
          sx={{
            px: 7,
            py: 1,
          }}
        >
          {translate("contentTopic.showMore")}
        </Button>
      )}
    </Box>
  );
};

const ContentTopicSelector = ({
  selectedContentTopics,
  locale,
  title,
  itemsPerView,
  showMore,
  showMoreItems,
  itemsLimit,
}) => {
  const { translate } = useContext(LocalizationContext);

  const [matchingContentPages, setMatchingContentPages] = useState(
    selectedContentTopics &&
      useAllContentByContentTopics(selectedContentTopics, locale)
  );

  const displayPages =
    itemsLimit &&
    matchingContentPages &&
    itemsLimit < matchingContentPages.matchingContent.length
      ? matchingContentPages.matchingContent.slice(0, itemsLimit)
      : matchingContentPages.matchingContent;
  const resultCount = displayPages.length;
  const [next, setNext] = useState(itemsPerView);
  const handleShowMore = () => {
    setNext(next + showMoreItems);
  };

  return (
    <Box>
      {title && <Themed.h2>{title}</Themed.h2>}
      {resultCount === 0 ? (
        <Themed.p>{translate("contentTopic.noResultsFound")}</Themed.p>
      ) : (
        <Box>
          <Grid
            aria-live="polite"
            sx={{
              gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
            }}
          >
            {displayPages?.slice(0, next)?.map((item, index) => {
              return (
                <ContentTopicItem
                  item={item}
                  index={index}
                  key={item.contentful_id}
                />
              );
            })}
          </Grid>
        </Box>
      )}
      {showMore && (
        <Flex
          sx={{
            justifyContent: "center",
            mt: 2,
            mb: 6,
          }}
        >
          <ShowMoreContent
            displayPages={displayPages}
            next={next}
            handleShowMore={handleShowMore}
            setNext={setNext}
            itemsPerView={itemsPerView}
            translate={translate}
          />
        </Flex>
      )}
    </Box>
  );
};

export default ContentTopicSelector;
