/** @jsx jsx */
import ReactDOMServer from "react-dom/server";
import React from "react";
import { jsx, Box, Themed, Flex } from "theme-ui";
import { useContext } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import Link from "./Link";
import Tooltip from "./Tooltip";
import { InfoIcon } from "./Icon";
import { forEach } from "mathjs";

const Contact = ({
  visibleTitle,
  tooltip,
  description,
  phone,
  link,
  smallMargin,
  ...props
}) => {
  const { translate, getLocale } = useContext(LocalizationContext);
  phone = !phone ? "" : phone;
  phone = phone.replace(/###/g, "\n");
  phone = phone.replace(/[0-9][0-9 -]*[0-9]/g, "###$&###");
  let phoneChunks = phone.split("###") || phone;

  return (
    <Box
      sx={{
        width: ["100%", "50%", null, null],
        mb: smallMargin ? [2, 3, 4] : [4, 5, 6],
        ":nth-last-child(-n+2)": {
          mb: [4, 0, 0],
        },
      }}
      {...props}
    >
      <Flex>
        <Themed.strong>{visibleTitle}</Themed.strong>
        {tooltip && (
          <Tooltip placement="top" tooltip={tooltip}>
            <InfoIcon sx={{ color: "primary", marginLeft: 2 }} />
          </Tooltip>
        )}
      </Flex>
      <Themed.p
        sx={{
          m: 0,
        }}
      >
        {description}
      </Themed.p>
      {phone && (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {phoneChunks.map((item, index) => {
            if (item.match(/[0-9][0-9 -]*[0-9]/g)) {
              return <Link target={item}>{item}</Link>;
            } else {
              return item;
            }
          })}
        </div>
      )}

      {link && link.slug && (
        <Link
          sx={{
            display: "block",
          }}
          target={link.slug}
        >
          {translate("contact.linkText")}
        </Link>
      )}
    </Box>
  );
};
export default Contact;
