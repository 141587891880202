/** @jsx jsx */
import { jsx, Container, Themed, Flex } from "theme-ui";
import RichText2 from "./RichText2";
import { GatsbyImage } from "gatsby-plugin-image";
import colors from "../gatsby-plugin-theme-ui/colors";

const InlineImage = ({
  title,
  hideTitle,
  locale,
  imageSize,
  imageLocation,
  content,
  image,
  language,
  color,
}) => {
  // 1 = large, 2 = medium, 3 = small, 4 = icon
  let itemSize, variant;
  switch (imageSize) {
    case 1:
      itemSize = "85%";
      break;
    case 2:
      itemSize = "55%";
      break;
    case 3:
      itemSize = "25%";
      break;
    case 4:
      itemSize = "12%";
      variant = "icon";
      break;
    default:
      itemSize = "90%";
  }
  let flexDirection;
  switch (imageLocation) {
    case true:
      flexDirection = "row";
      break;
    case false:
      flexDirection = "row-reverse";
      break;
    default:
      flexDirection = "row";
  }
  let background, textColor;
  switch (color) {
    case "lightGrey":
      background = colors.muted;
      textColor = colors.black;
      break;
    case "lightGreen":
      background = colors.lightGreen;
      textColor = colors.black;
      break;
    case "green":
      background = colors.primary;
      textColor = colors.background;
      break;
    case "error":
      background = colors.error;
      textColor = colors.background;
      break;
    case "blue":
      background = colors.blue;
      textColor = colors.background;
      break;
    default:
      background = colors.background;
  }
  if (variant === "icon") {
    return (
      <Flex
        sx={{
          flexDirection: flexDirection,
          flexWrap: "nowrap",
          color: color,
          py: 2,
          my: 1,
          color: textColor,
          backgroundColor: background,
          "@media print": {
            flexWrap: "nowrap",
          },
        }}
      >
        <Container
          sx={{
            minWidth: "90px",
            flexBasis: itemSize,
            pr: [2, 2, 2],
            mt: 3,
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt={image && image.description ? image.description : ""}
          />
        </Container>
        <Container
          sx={{
            pl: [0, 1, 1],
            pr: 3,
          }}
        >
          {hideTitle ? "" : <Themed.h2>{title}</Themed.h2>}
          <RichText2 content={content} locale={locale} language={language} />
        </Container>
      </Flex>
    );
  } else {
    return (
      <Flex
        sx={{
          flexDirection: flexDirection,
          flexWrap: ["wrap", "nowrap", "nowrap"],
          color: color,
          py: 4,
          my: 1,
          color: textColor,
          backgroundColor: background,
          "@media print": {
            flexWrap: "nowrap",
          },
        }}
      >
        <Container
          sx={{
            mb: 3,
            minWidth: "140px",
            flexBasis: itemSize,
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt={image && image.description ? image.description : ""}
          />
        </Container>
        <Container>
          {hideTitle ? (
            ""
          ) : (
            <Themed.h2
              sx={{
                mt: [0, 0, 0],
              }}
            >
              {title}
            </Themed.h2>
          )}
          <RichText2 content={content} locale={locale} language={language} />
        </Container>
      </Flex>
    );
  }
};

export default InlineImage;
