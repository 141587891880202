/** @jsx jsx */
import { jsx, Box, Button, Flex, Themed, useThemeUI } from "theme-ui";

const DynamicSelectors = ({ data, selectedValue, callback, ...props }) => {
  const { theme } = useThemeUI();
  const { formFields } = data;

  {
    return formFields.map((item, questionIndex) => {
      let questionLabel =
        props.language === "sv" && item.label_sv ? item.label_sv : item.label;
      let questionDescription =
        props.language === "sv" && item.description_sv
          ? item.description_sv
          : item.description;
      return (
        <Flex
          key={`questions-${questionIndex}`}
          sx={{
            mx: "-0.2em",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "flex-start",
          }}
          {...props}
        >
          {item.label && (
            <Themed.h2
              sx={{
                marginRight: "1em",
              }}
            >
              {questionLabel}
            </Themed.h2>
          )}
          {item.description && (
            <Themed.p
              sx={{
                marginRight: "1em",
              }}
            >
              {questionDescription}
            </Themed.p>
          )}
          <Box>
            {item.values.map((value, index) => {
              let label =
                props.language === "sv" && value.label_sv
                  ? value.label_sv
                  : value.label;
              return (
                <Button
                  key={`queston-option-${index}`}
                  aria-controls={`content-card-theme-${value.label}`}
                  sx={{
                    variant:
                      selectedValue[questionIndex] &&
                      selectedValue[questionIndex].currentValue === value.id
                        ? "buttons.primary"
                        : "buttons.secondary",
                    whiteSpace: "normal",
                    mx: "0.2em",
                    my: "1em",
                    fontWeight: "bold",
                    textAlign: "center",
                    ":hover": {
                      border: theme.colors.hover,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      color: "white",
                      bg: "green",
                    },
                  }}
                  onClick={() => callback(value.id, questionIndex)}
                >
                  {label}
                </Button>
              );
            })}
          </Box>
        </Flex>
      );
    });
  }
};

export default DynamicSelectors;
