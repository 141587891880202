/** @jsx jsx */
import { jsx, Themed, Box, Flex, useThemeUI } from "theme-ui";
import React, { useState } from "react";
import { CaretIcon } from "./Icon";
import VisuallyHidden from "./VisuallyHidden";

const FeatureIcon = ({ label, optional = false }) => (
  <Box
    aria-label={label}
    title={label}
    sx={{
      display: "inline-block",
      width: 16,
      height: 16,
      borderRadius: 16,
      borderWidth: optional ? 2 : 8,
      borderStyle: "solid",
      borderColor: "primary",
    }}
  />
);

const getCellValue = (value) => {
  const theme = useThemeUI();
  if (typeof value === "boolean") {
    return value ? (
      <FeatureIcon label="Sisältyy" />
    ) : (
      <VisuallyHidden children="Ei sisälly" />
    );
  } else if (value === "optional") {
    return <FeatureIcon optional label="Valittavissa" />;
  }
  return value;
};

const InsuranceProductData = ({ row, headers }) => {
  const { name, description, ...data } = row;
  const [open, setOpen] = useState(false);
  const change = () => {
    setOpen(!open);
  };
  const keyPressed = (event) => {
    if (
      event.key === " " ||
      event.key === "Enter" ||
      event.key === "Spacebar"
    ) {
      // "Spacebar" for IE11 support
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault();
      setOpen(!open);
    }
  };

  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {" "}
        {text} <br />{" "}
      </React.Fragment>
    ));

  return (
    <Flex
      scope="row"
      role="row"
      sx={{
        flexWrap: "wrap",
        width: "100%",
        py: 12,
        px: 1,
        ":nth-child(even)": {
          bg: "lightGreen",
        },
      }}
    >
      <Box
        role="rowheader"
        sx={{
          flex: ["none", "auto"],
          width: ["100%", 100 - headers.length * 15 + "%"],
          "@media print": {
            flex: "auto",
            width: "20%",
            breakInside: "avoid",
          },
        }}
      >
        {description ? (
          <Flex
            onClick={change}
            onKeyPress={keyPressed}
            role="button"
            aria-expanded={open}
            tabIndex={0}
            //aria-controls={1}
            sx={{
              cursor: "pointer",
              minHeight: 24,
            }}
          >
            <CaretIcon
              sx={{
                flex: "none",
                width: 16,
                height: 10,
                mt: ".5em",
                mr: 1,
                transform: open ? "rotate(180deg)" : "none",
                transition: "transform .2s ease",
              }}
              aria-hidden="true"
            />
            {name}
          </Flex>
        ) : (
          <Box sx={{ pl: 3 }}>{name}</Box>
        )}
      </Box>
      {description && (
        <Box
          sx={{
            flex: "none",
            display: open ? "block" : "none",
            width: "100%",
            pt: open ? 2 : 0,
            pl: 3,
            order: [0, 1],
          }}
        >
          {addLineBreaks(description)}
        </Box>
      )}
      {headers.map((header, index) => {
        return (
          <Box
            key={index}
            role="cell"
            sx={{
              textAlign: "center",
              flex: "none",
              width: [(1 / headers.length) * 100 + "%", "15%"],
              mt: [2, 0],
              wordWrap: "break-word",
              "@media print": {
                width: "20%",
                mt: 0,
              },
            }}
          >
            {getCellValue(
              data[header] ? data[header] : data[header.replace(/- /, "")]
            )}
          </Box>
        );
      })}
    </Flex>
  );
};

const InsuranceProductTable = ({ data, anchor }) => {
  const { headers, title, rows } = data;
  if (!headers || !title || !rows) return null;

  return (
    <Box id={anchor} sx={{ scrollMarginTop: "104px" }}>
      <Themed.h2 id="productTableTitle">{title}</Themed.h2>
      <Box
        sx={{ mx: [-3, 0] }}
        role="table"
        aria-describedby="productTableTitle"
      >
        <Flex
          sx={{
            flexWrap: ["wrap", "nowrap"],
            alignItems: "flex-end",
            width: "100%",
            position: "sticky",
            top: [64, null, 103],
            bg: "white",
            zIndex: 10,
            "@media print": {
              position: "static",
            },
          }}
          role="row"
        >
          {headers.map((header, index) =>
            header ? (
              <Box
                scope="col"
                role="columnheader"
                key={header}
                sx={{
                  flex: [1, "none"],
                  width: [(1 / headers.length) * 100 + "%", "15%"],
                  wordWrap: "break-word",
                  textAlign: "center",
                  p: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: "grey",
                  borderBottomWidth: "2px",
                  fontSize: 1,
                  "@media print": {
                    flex: "none",
                    width: "20%",
                  },
                }}
              >
                {header}
              </Box>
            ) : (
              <Box
                key={index}
                sx={{
                  display: ["none", "block"],
                  flex: 1,
                  width: [0, "auto"],
                  py: 12,
                  px: 2,
                  borderBottomStyle: "solid",
                  borderBottomColor: "grey",
                  borderBottomWidth: "2px",
                  "@media print": {
                    display: "block",
                    width: "auto",
                  },
                }}
              ></Box>
            )
          )}
        </Flex>
        {rows.map((row) => (
          <InsuranceProductData
            key={row.name}
            row={row}
            headers={headers.slice(1)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InsuranceProductTable;
