import React from "react";
import { jsx, Themed, Flex, Container } from "theme-ui";
import RichText2 from "./RichText2";
const pdfSize = 700; // PDFwidth in pixels

const TwoColumns = ({
  title,
  hideTitle,
  leftColumn,
  rightColumn,
  locale,
  language,
  ...props
}) => {
  return (
    <Container variant="wide" sx={{
      "@media print": {
        breakInside: 'avoid'
      }
    }}>
      {hideTitle ? "" : <Themed.h3>{title}</Themed.h3>}
      <Flex sx={{ flexWrap: ["wrap", null, null, "nowrap"] }}>
        <Container variant="wide" sx={{
          marginRight: [0, null, null, 2], "@media print": {
            width: '50%',
            flex: 'wrap'
          }
        }}>
          <RichText2
            content={leftColumn}
            locale={locale}
            language={language}
          />
        </Container>
        <Container variant="wide" sx={{
          marginLeft: [0, null, null, 2], "@media print": {
            width: '50%',
            flex: 'wrap'
          }
        }}>
          <RichText2
            content={rightColumn}
            locale={locale}
            language={language}
          />
        </Container>
      </Flex>
    </Container>
  );
};

export default TwoColumns;
