import { useStaticQuery, graphql } from "gatsby";

export const useMostRecentContentHubPages = () => {
  const { allContentfulContentHubPage } = useStaticQuery(
    graphql`
      query MostRecentContentHubPagesQuery {
        allContentfulContentHubPage(filter: {hidden: {ne: true}}, sort: {order: DESC, fields: publishDate}) {
          group(field: node_locale, limit: 3) {
            fieldValue
            nodes {
              id
              contentful_id
              __typename
              title
              slug
              publishDate(formatString: "X")
            }
          }
        }
      }
    `
  );

  const { group } = allContentfulContentHubPage;
  const pagesByTheme = group.map((item) => {
    const { fieldValue, nodes } = item;
    return { locale: fieldValue, items: nodes };
  });

  return pagesByTheme;
};
