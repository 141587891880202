/** @jsx jsx */
import { jsx } from "theme-ui";

const Iframe = ({ data, anchor, ...rest }) => {
  const { width, url, height } = data;
  return (
    <div id={anchor} sx={{ scrollMarginTop: "104px" }}>
        <iframe 
            src={url} 
            width={width} 
            height={height} 
            scrolling="auto" 
            frameBorder="0" 
            allowtransparency="true" 
            allowFullscreen="true"
            />
      </div>
  );
};

export default Iframe;
