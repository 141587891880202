/** @jsx jsx */
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Children, useContext } from "react";
import { Box, Container, Flex, jsx, Themed } from "theme-ui";
import { LocalizationContext } from "../context/LocalizationContext";
import colors from "../gatsby-plugin-theme-ui/colors";
import useContentfulVariables from "../hooks/useContentfulVariables";
import { matchAndReplace } from "../utils/utils";
import Accordion from "./Accordion";
import Attachements from "./Attachements";
import CallToAction, { Types } from "./CallToAction";
import Contact from "./Contact";
import ContentfulComponent from "./ContentfulComponent";
import ContentHubLiftUps from "./ContentHubLiftUps";
import CoverageGrid from "./CoverageGrid";
import { DynamicSection } from "./DynamicSection";
import EmbeddedNotification from "./EmbeddedNotification";
import Hero from "./Hero";
import InlineImage from "./InlineImage";
import LiftUpBox from "./LiftUpBox";
import LiftUpColor from "./LiftUpColor";
import LiftupEasyStep from "./LiftupEasyStep";
import LiftUpLinks from "./LiftUpLinks";
import LiftUpTextBody from "./LiftUpTextBody";
import Link from "./Link";
import ResponsiveIframe from "./ResponsiveIframe";
import TwoColumns from "./TwoColumns";
import ProcessGuide from "./ProcessGuide";
import ContentTopicSelector from "./ContentTopicSelector";

const defaultLocale = "fi-FI";

const baseLink = {
  fontWeight: "bold",
  //borderBottom: "1px solid",
  //borderBottomColor: "primary",
};

const options = (locale, language, template) => ({
  renderText: (text) =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.BOLD]: (text) => <Themed.strong>{text}</Themed.strong>,
    [MARKS.ITALIC]: (text) => <Themed.em>{text}</Themed.em>,
  },

  renderNode: {
    wrapper: (node, children) => {
      switch (
        node.content &&
        node.content[0].data &&
        node.content[0].data.target &&
        ((node.content[0].data.target.internal &&
          node.content[0].data.target.internal.type) ||
          node.content[0].data.target.__typename)
      ) {
        case "ContentfulAccordion":
          return <div>{children}</div>;
        case "ContentfulContact":
          return (
            <Flex as="section" sx={{ flexWrap: "wrap", width: "100%" }}>
              {children}
            </Flex>
          );
        default:
          return children;
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Themed.p>{children}</Themed.p>,
    [BLOCKS.HEADING_1]: (node, children) => <Themed.h1>{children}</Themed.h1>,
    [BLOCKS.HEADING_2]: (node, children) =>
      template === "insuranceTerms" ? (
        <Themed.h2 id={`${node.content[0].value}-link-id-${node.nodeIndex}`}>
          {children}
        </Themed.h2>
      ) : (
        <Themed.h2>{children}</Themed.h2>
      ),
    [BLOCKS.HEADING_3]: (node, children) => <Themed.h3>{children}</Themed.h3>,
    [BLOCKS.HEADING_4]: (node, children) => <Themed.h4>{children}</Themed.h4>,
    [BLOCKS.HEADING_5]: (node, children) => <Themed.h5>{children}</Themed.h5>,
    [BLOCKS.HEADING_6]: (node, children) => <Themed.h6>{children}</Themed.h6>,
    [BLOCKS.UL_LIST]: (node, children) => <Themed.ul>{children}</Themed.ul>,
    [BLOCKS.OL_LIST]: (node, children) => <Themed.ol>{children}</Themed.ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => {
      // Remove <p> tags inside list elements, but preserve object hierarchy
      const manipulatedChildren = Children.map(children, (child) => {
        if (child.type && child.type.displayName === "Themed(p)") {
          return child.props.children;
        }
        return child;
      });
      return <Themed.li>{manipulatedChildren}</Themed.li>;
    },
    [BLOCKS.HR]: (node, children) => <Themed.hr />,
    [BLOCKS.QUOTE]: (node, children) => (
      <Themed.blockquote>{children}</Themed.blockquote>
    ),

    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      let target = node.data && node.data.target;

      switch (
        target &&
        (target.__typename || (target.internal && target.internal.type))
      ) {
        case "ContentfulLiftUpLinks": {
          const title = target.title;
          const columns = target.columns;
          const typeoflink = target.typeOfLink;
          const hideTitle = target.hideTitle;

          const links = target.links.map((link) => {
            return {
              navigationTitle: link.navigationTitle,
              navigationIcon: link.navigationIcon,
              hideLinkTitle: link.hideLinkTitle,
              slug:
                link.slug ||
                link.url ||
                (link.mediaAssetfile &&
                  link.mediaAssetfile.file &&
                  link.mediaAssetfile.file.url),
              hero: link.hero,
              cid: link.id,
            };
          });

          return (
            <LiftUpLinks
              title={title}
              links={links}
              columns={columns}
              typeoflink={typeoflink}
              hideTitle={hideTitle}
              locale={defaultLocale}
            />
          );
        }
        case "ContentfulLiftUpBox":
          return (
            <LiftUpBox target={target} locale={locale} language={language} />
          );

        case "ContentfulCallToAction":
          return (
            <Box
              sx={
                {
                  /*
                  my:
                    target.type["fi-FI"] === "Full"
                      ? [5, null, 6]
                      : null,
                      */
                }
              }
            >
              <CallToAction
                locale={locale}
                title={target.title}
                linkText={target.linkText}
                link={target.link}
                target={target.target}
                type={target.type}
                color={target.color}
                analyticsAction={target.analyticsAction}
                analyticsId={target.analyticsId}
                contentful_id={target.contentful_id}
              />
            </Box>
          );

        case "ContentfulAssets": {
          return (
            <Attachements
              locale={locale}
              title={target.title}
              attachements={target.file}
              urls={target.url}
            />
          );
        }

        case "ContentfulComponent":
          const data =
            node.data.target.data &&
            node.data.target.data.internal &&
            node.data.target.data.internal.content
              ? JSON.parse(node.data.target.data.internal.content)
              : {};
          return (
            <ContentfulComponent
              title={target.title}
              template={target.template}
              data={data}
              anchor={target.anchor}
              locale={locale}
              language={language}
            />
          );

        case "ContentfulAccordion":
          return (
            <Accordion
              title={target.title}
              content={target.content}
              anchorLink={target.anchorLink}
              index={node.wrapperIndex}
              locale={locale}
              language={language}
            />
          );

        case "ContentfulContact":
          return (
            <Contact
              visibleTitle={target.visibleTitle}
              tooltip={target.tooltip}
              description={target.description}
              phone={target.phone}
              link={target.link}
              smallMargin={target.smallMargin}
              className="contact"
            />
          );

        case "ContentfulGrid":
          let itemCount = (target.components && target.components.length) || 0;
          return (
            <Flex
              sx={{
                flexDirection: ["column", null, "row"],
                mx: [-3, 0, -2],
              }}
            >
              {target.components &&
                target.components.map((component, index) => {
                  if (component.internal.type === "ContentfulComponent") {
                    const data =
                      component.data &&
                      component.data.internal &&
                      component.data.internal.content
                        ? JSON.parse(component.data.internal.content)
                        : {};
                    return (
                      <Box
                        key={index}
                        sx={{
                          width: ["100%", null, (1 / itemCount) * 100 + "%"],
                          mb: [4, null, 0],
                          px: [0, null, 2],
                          ":last-of-type": { mb: 0 },
                          "> div": {
                            height: ["auto", null, "100%"],
                          },
                        }}
                      >
                        <ContentfulComponent
                          key={component.id}
                          title={component.title}
                          template={component.template}
                          data={data}
                          anchor={component.anchor}
                          locale={locale}
                          language={language}
                        />
                      </Box>
                    );
                  } else if (
                    component.internal.type === "ContentfulCallToAction"
                  ) {
                    const isButtonCTA =
                      component.type === Types.BUTTON ||
                      component.type === Types.BUTTON_SECONDARY;
                    return (
                      <Box
                        sx={{
                          width: isButtonCTA
                            ? null
                            : ["100%", null, (1 / itemCount) * 100 + "%"],
                          mb: [4, null, 0],
                          px: [isButtonCTA ? 3 : 0, null, isButtonCTA ? 1 : 2],
                          ":last-of-type": { mb: 0 },
                          "> div": {
                            height: ["auto", null, "100%"],
                          },
                          ":first-of-type": {
                            paddingLeft: [
                              isButtonCTA ? 3 : null,
                              null,
                              isButtonCTA ? 2 : null,
                            ],
                          },
                        }}
                      >
                        <CallToAction
                          locale={locale}
                          title={component.title}
                          linkText={component.linkText}
                          link={component.link}
                          description={component.description}
                          target={component.target}
                          type={component.type}
                          analyticsAction={component.analyticsAction}
                          analyticsId={component.analyticsId}
                          contentful_id={component.sys && component.sys.id}
                        />
                      </Box>
                    );
                  } else {
                    return null;
                  }
                })}
            </Flex>
          );

        case "ContentfulHero": {
          return (
            <Box sx={{ mx: [-3, -4, 0] }}>
              <Hero
                title={target.title}
                type={target.type}
                image={target.image}
                description={target.description}
                linkText={target.linkText}
                link={target.link}
                target={target.target}
                analyticsAction={target.analyticsAction}
                analyticsId={target.analyticsId}
                contentful_id={target.contentful_id}
                videoEmbedUrl={target.videoEmbedUrl}
                videoEmbedTitle={target.videoEmbedTitle}
              />
            </Box>
          );
        }
        case "ContentfulProcessGuide":
          return <ProcessGuide text={target.text} items={target.items} />;
        case "ContentfulContentTopicSelector":
          return (
            <ContentTopicSelector
              title={target.title}
              selectedContentTopics={target.contentTopics}
              itemsPerView={target.itemsPerView}
              showMore={target.showMore}
              showMoreItems={target.showMoreItems}
              itemsLimit={target.itemsLimit}
              locale={locale}
            />
          );
        case "ContentfulLiftUpEasyStep":
          return (
            <Box sx={{ mx: [-3, -4] }}>
              <LiftupEasyStep locale={locale} language={language} {...target} />
            </Box>
          );

        case "ContentfulInlineImage":
          return (
            <Box sx={{ mx: [-3, -4], breakInside: "avoid" }}>
              <InlineImage
                title={target.title}
                hideTitle={target.hideTitle}
                imageSize={target.imageSize}
                imageLocation={target.imageLocation}
                content={target.content}
                image={target.inlineImage}
                locale={locale}
                language={language}
                color={target.backgroundColor}
              />
            </Box>
          );
        case "liftUpTextBody":
          return (
            <LiftUpTextBody
              title={target.title}
              content={target.content}
              locale={locale}
              language={language}
            />
          );
        case "ContentfulResponsiveIframe":
          return (
            <ResponsiveIframe
              url={target.url}
              embedType={target.embedType}
              description={target.description}
            />
          );
        case "ContentfulLiftUpColor":
          return (
            <LiftUpColor
              title={target.title}
              content={target.content}
              color={target.color}
              hideTitle={target.hideTitle}
              locale={locale}
              language={language}
            />
          );
        case "ContentfulCoverageGrid":
          return (
            <CoverageGrid
              title={target.title}
              boxes={target.boxes}
              columns={target.columns}
              hideTitle={target.hideTitle}
              locale={locale}
              language={language}
            />
          );
        case "ContentfulTwoColumns":
          return (
            <TwoColumns
              title={target.title}
              leftColumn={target.leftColumn}
              rightColumn={target.rightColumn}
              hideTitle={target.hideTitle}
              locale={locale}
              language={language}
            />
          );

        case "ContentfulDynamicSection":
          return (
            <DynamicSection
              locale={locale}
              language={language}
              dynamicContentBlocks={target.dynamicContentBlocks}
              selectors={target.selectors}
            />
          );

        case "ContentfulEmbeddedNotification":
          return (
            <EmbeddedNotification
              title={target.title}
              message={target.message}
              severity={target.severity}
              validFrom={target.validFrom}
              validTo={target.validTo}
              linkText={target.linkText}
              linkUrl={target.linkUrl}
              locale={locale}
              language={language}
            />
          );

        case "ContentfulInlineImageFlex":
          let background, textColor;
          switch (target && target.backgroundColor) {
            case "lightGrey":
              background = colors.muted;
              textColor = colors.black;
              break;
            case "lightGreen":
              background = colors.lightGreen;
              textColor = colors.black;
              break;
            case "green":
              background = colors.primary;
              textColor = colors.background;
              break;
            case "error":
              background = colors.error;
              textColor = colors.background;
              break;
            case "blue":
              background = colors.blue;
              textColor = colors.background;
              break;
            default:
              background = colors.background;
          }

          return (
            <Container
              variant="wide"
              sx={{
                bg: background,
                margin: "0",
                width: "100%",
                padding: "20px 0px",
                display: "flex",
                flexDirection: ["column", "column", target.flexDirection],
              }}
            >
              {target.inlineImages.map((item, index) => {
                return (
                  <Container
                    variant="wide"
                    sx={{ padding: "5px 0px" }}
                    key={index}
                  >
                    <InlineImage
                      title={item.title}
                      hideTitle={item.hideTitle}
                      imageSize={item.imageSize}
                      imageLocation={item.imageLocation}
                      content={item.content}
                      color={item.backgroundColor}
                      textColor={textColor}
                      image={item.inlineImage}
                      locale={locale}
                      language={language}
                    />
                  </Container>
                );
              })}
            </Container>
          );

        case "ContentfulResponsiveIframe":
          return (
            <ResponsiveIframe url={target.url} embedType={target.embedType} />
          );
        case "ContentfulContentHubLiftUps": {
          const parentSlug =
            target.summaryPage &&
            target.summaryPage.parentPage &&
            target.summaryPage.parentPage.slug;
          const childSlug = target.summaryPage && target.summaryPage.slug;

          const summaryPageSlug =
            parentSlug && childSlug ? parentSlug + "/" + childSlug : undefined;

          return (
            /* additional div element disables the "CSS vuoto bug"... a css class "leaks" / gets added from parent element to this element when it should not (in this case inline image entry as parent witout the div vill trigger the leak) */
            <div>
              <ContentHubLiftUps
                title={target.heading}
                rawThemes={target.themes}
                rawContentTypes={target.contentTypes}
                rawTopics={target.topics}
                rawAuthors={target.authors}
                summaryPageSlug={summaryPageSlug}
                locale={locale}
                language={language}
              />
            </div>
          );
        }

        default:
          break;
      } // end switch
    }, // end [BLOCKS.EMBEDDED_ENTRY]

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (!node.data.target) return;
      const image = (
        <img
          alt={node.data.target.description}
          src={node.data.target.file.url}
          sx={{ maxWidth: "100%" }}
        />
      );
      return image;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      //const { value } = (node.content || [])[0] || {};
      const { uri } = node.data || {};
      // breaks some pdf links
      /**try {
        const url = new URL(uri || "");
        if (["www.fennia.fi", "fennia.fi"].includes(url.host)) {
          const to = url.pathname + url.search + url.hash;
          //TODO: check localization
          return (
            <Link target={to} sx={{ ...baseLink }}>
              {value}
            </Link>
          );
        }
      } catch (error) {
        // ignore, render absolute uri
      }*/
      if (!children) return null;
      return (
        <Link
          key={children + "-" + Math.floor(Math.random() * 1000 + 1)}
          target={uri}
          sx={{
            ...baseLink,
          }}
        >
          {children}
        </Link>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      //let linkText = node.content[0].value;
      return (
        <Link
          key={children + "-" + Math.floor(Math.random() * 1000 + 1)}
          target={node.data && node.data.target && node.data.target.slug} // TODO: fix graphQL query for component
          sx={{ ...baseLink }}
        >
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const url = node.data.target && node.data.target.file.url;
      const value = node.content && node.content[0].value;
      if (url && value)
        return (
          <Link key={value} target={url} sx={{ ...baseLink }}>
            {value}
          </Link>
        );
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      switch (
        node.data.target &&
        node.data.target.internal && // TODO: fix graphQL query for component
        node.data.target.internal.type
      ) {
        case "ContentfulCallToAction":
          return (
            <Box>
              <CallToAction
                locale={locale}
                title={node.data.target.title}
                linkText={node.data.target.linkText}
                link={node.data.target.link}
                description={node.data.target.description}
                target={node.data.target.target}
                type={node.data.target.type}
                analyticsAction={node.data.target.analyticsAction}
                analyticsId={node.data.target.analyticsId}
                contentful_id={node.data.contentful_id}
              />
            </Box>
          );
        case "ContentfulComponent":
          const data =
            node.data.target.data &&
            node.data.target.data.internal &&
            node.data.target.data.internal.content
              ? JSON.parse(node.data.target.data.internal.content)
              : {};
          return (
            <ContentfulComponent
              title={node.data.target.title}
              template={node.data.target.template}
              data={data}
              anchor={node.data.target.anchor}
              locale={locale}
              language={language}
            />
          );
        case "ContentfulAsset":
          return <p>----ContentfulAsset-----</p>;
        default:
          //TODO: localization if needed

          if (node.data.target && node.data.target.target) {
            // TODO: fix graphQL query for component
            return (
              <Box>
                <Link target={node.data.target.target} sx={{ ...baseLink }}>
                  {node.data.target.title}
                </Link>
              </Box>
            );
          }

          break;
      }
    },
  },
});

const addWrapperForEmbeddedEntries = (content, contentfulVariables, locale) => {
  if (!content || !Array.isArray(content)) return content;
  const newContent = [];
  for (let i = 0; i < content.length; i++) {
    const element = content[i];
    if (element.content) {
      element.content = addWrapperForEmbeddedEntries(
        element.content,
        contentfulVariables,
        locale
      );
    }

    replaceContentVariables(element, contentfulVariables, locale);

    let nextElement = i + 1 < content.length ? content[i + 1] : null;
    if (
      element.nodeType === "embedded-entry-block" &&
      nextElement &&
      nextElement.nodeType === "embedded-entry-block" &&
      nextElement.contentfulType === element.contentfulType
    ) {
      const wrapperObject = {
        data: {},
        content: [],
        nodeType: "wrapper",
        contentType: element.contentfulType,
      };
      element.wrapperIndex = 0;
      wrapperObject.content.push(element);
      while (
        nextElement &&
        nextElement.nodeType === "embedded-entry-block" &&
        nextElement.contentfulType === element.contentfulType
      ) {
        if (nextElement.content) {
          nextElement.content = addWrapperForEmbeddedEntries(
            element.content,
            contentfulVariables,
            locale
          );
        }
        nextElement.wrapperIndex = i;
        wrapperObject.content.push(nextElement);
        i = i + 1;
        nextElement = i + 1 < content.length ? content[i + 1] : null;
      }
      newContent.push(wrapperObject);
    } else {
      newContent.push(element);
    }
  }
  return newContent;
};

const replaceContentVariables = (element, contentfulVariables, locale) => {
  if (element.nodeType === "text" && element.value) {
    element.value = matchAndReplace(element.value, contentfulVariables);
  } else if (
    element.nodeType === "hyperlink" &&
    element.data &&
    element.data.uri
  ) {
    element.data.uri = matchAndReplace(element.data.uri, contentfulVariables);
  } else if (
    (element.nodeType === "embedded-entry-block" ||
      element.nodeType === "embedded-entry-inline") &&
    element.contentfulType === "ContentfulCallToAction" &&
    element.data.target.fields
  ) {
    if (
      element.data.target.fields.title &&
      element.data.target.fields.title[locale]
    ) {
      element.data.target.fields.title[locale] = matchAndReplace(
        element.data.target.fields.title[locale],
        contentfulVariables
      );
    }

    if (
      element.data.target.fields.linkText &&
      element.data.target.fields.linkText[locale]
    ) {
      element.data.target.fields.linkText[locale] = matchAndReplace(
        element.data.target.fields.linkText[locale],
        contentfulVariables
      );
    }

    if (
      element.data.target.fields.target &&
      element.data.target.fields.target[locale]
    ) {
      element.data.target.fields.target[locale] = matchAndReplace(
        element.data.target.fields.target[locale],
        contentfulVariables
      );
    }
  } else if (
    (element.nodeType === "embedded-entry-block" ||
      element.nodeType === "embedded-entry-inline") &&
    element.contentfulType === "inlineImage" &&
    element.data.target.fields &&
    element.data.target.fields.title
  ) {
    element.data.target.fields.title[locale] = matchAndReplace(
      element.data.target.fields.title[locale],
      contentfulVariables
    );
  }
};

export default ({ content, locale, language, template }) => {
  if (!(content && content.raw)) return null;
  var h2Counter = 1;

  const { getLocale } = useContext(LocalizationContext);
  locale = locale || getLocale();
  const contentfulVariables = useContentfulVariables();

  const rawObj = JSON.parse(content.raw);
  rawObj.content.forEach((obj, i) => {
    rawObj.content[i]["contentfulType"] = obj.nodeType;
    if (template === "insuranceTerms" && obj.nodeType === "heading-2") {
      rawObj.content[i]["nodeIndex"] = h2Counter++;
    }
    try {
      if (obj.nodeType === "embedded-entry-block") {
        let refObj = content.references.filter(
          (rObj) => rObj.contentful_id === obj.data.target.sys.id
        );
        rawObj.content[i]["contentfulType"] = refObj[0].__typename;
      }
    } catch (e) {
      /*console.log(
        "Error RitchText2, probably missing embedded-entry reference in GraphQL query" +
          e.message
      );*/
    }
  });

  rawObj.content = addWrapperForEmbeddedEntries(
    rawObj.content,
    contentfulVariables,
    locale
  );
  content.raw = JSON.stringify(rawObj);

  return (
    <Box
      sx={{
        "> div, > h1, > h2, > h3, > h4, > div + p": { mt: [4, null, 4] },
        mb: [4, null, 4],
      }}
    >
      {renderRichText(content, options(locale, language, template))}
    </Box>
  );
};
