/** @jsx jsx */
import { jsx, Flex, Box, Grid, useThemeUI } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";

const ProcessGuide = ({ items }) => {
  const { theme } = useThemeUI();

  const handleColumnCount = (itemCount) => {
    switch (itemCount) {
      case 1:
        return [1];
      case 2:
        return [1, 2];
      case 3:
        return [1, 3];
      case 4:
        return [1, 2, 4];
      case 5:
        return [1, 2, 3];
      case 6:
        return [1, 2, 3];
      default:
        return [1, 2, 4];
    }
  };

  return (
    <Grid
      columns={handleColumnCount(items.length)}
      sx={{
        marginTop: 5,
        paddingTop: 4,
        columnGap: "0.2em",
        rowGap: "1.4em",
      }}
    >
      {items.map((item, index) => {
        return (
          <Flex
            key={item.text}
            sx={{
              backgroundColor: "lightGreen",
              paddingX: 2,
              paddingTop: [2, 3],
              paddingBottom: [3, 4],
              borderTop: `0.25em solid ${theme.colors.primary}`,
              fontSize: 0,
              fontWeight: "bold",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Flex
              sx={{
                color: "white",
                backgroundColor: "primary",
                fontSize: [3, 4],
                width: ["1.5em", "1.7em"],
                height: ["1.5em", "1.7em"],
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "-0.6em",
              }}
            >
              {index + 1}
            </Flex>
            <Box
              sx={{
                marginTop: 4,
                marginBottom: 2,
                maxWidth: ["5em", "7em"],
              }}
            >
              <GatsbyImage
                image={item.image.gatsbyImageData}
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                alt={
                  item.image && item.image.description
                    ? item.image.description
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                lineHeight: 1.2,
              }}
            >
              {item.text}
            </Box>
          </Flex>
        );
      })}
    </Grid>
  );
};

export default ProcessGuide;
