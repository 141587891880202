import { useStaticQuery, graphql } from "gatsby";

export const useAllContentfulContentHubPages = () => {
  const { allContentHubPagesFI, allContentHubPagesSV, allContentHubPagesEN } =
    useStaticQuery(
      graphql`
        query allContentHubPages {
          allContentHubPagesFI: allContentfulContentHubPage(
            filter: { node_locale: { eq: "fi-FI" }, hidden: { ne: true } }
            sort: { fields: publishDate, order: DESC }
          ) {
            edges {
              node {
                id
                authors {
                  name
                  authorHash
                }
                contentful_id
                __typename
                theme {
                  theme
                }
                lead
                slug
                title
                contentType {
                  name
                  node_locale
                  slug
                  icon {
                    fluid {
                      ...GatsbyContentfulFluid
                    }
                    file {
                      url
                    }
                  }
                }
                node_locale
                headerImage {
                  gatsbyImageData
                  description
                }
                publishDate(formatString: "X")
                parentPage {
                  slug
                }
                topic {
                  name
                }
              }
            }
          }
          allContentHubPagesSV: allContentfulContentHubPage(
            filter: { node_locale: { eq: "sv-SE" }, hidden: { ne: true } }
            sort: { fields: publishDate, order: DESC }
          ) {
            edges {
              node {
                id
                authors {
                  name
                  authorHash
                }
                contentful_id
                __typename
                theme {
                  theme
                }
                lead
                slug
                title
                contentType {
                  name
                  node_locale
                  slug
                  icon {
                    fluid {
                      ...GatsbyContentfulFluid
                    }
                    file {
                      url
                    }
                  }
                }
                node_locale
                headerImage {
                  gatsbyImageData
                }
                publishDate(formatString: "X")
                parentPage {
                  slug
                }
                topic {
                  name
                }
              }
            }
          }
          allContentHubPagesEN: allContentfulContentHubPage(
            filter: { node_locale: { eq: "en-US" }, hidden: { ne: true } }
            sort: { fields: publishDate, order: DESC }
          ) {
            edges {
              node {
                id
                authors {
                  name
                  authorHash
                }
                contentful_id
                __typename
                theme {
                  theme
                }
                lead
                slug
                title
                contentType {
                  name
                  node_locale
                  slug
                  icon {
                    fluid {
                      ...GatsbyContentfulFluid
                    }
                    file {
                      url
                    }
                  }
                }
                node_locale
                headerImage {
                  gatsbyImageData
                }
                publishDate(formatString: "X")
                parentPage {
                  slug
                }
                topic {
                  name
                }
              }
            }
          }
        }
      `
    );

  return {
    fi: allContentHubPagesFI.edges,
    sv: allContentHubPagesSV.edges,
    en: allContentHubPagesEN.edges,
  };
};
