/** @jsx jsx */
import { useState, useContext } from "react";
import { jsx, Themed, Flex, Box, Grid } from "theme-ui";
import { StarIcon, CarouselPrevIcon, CarouselNextIcon } from "./Icon";
import { useWindowSize } from "../hooks/useWindowSize";
import colors from "../gatsby-plugin-theme-ui/colors";
import { LocalizationContext } from "../context/LocalizationContext";

const MOBILE_BREAKPOINT = 768;

const isMobile = (currentWidth) => {
  return currentWidth < MOBILE_BREAKPOINT;
};

const CarouselView = ({ items }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const { translate } = useContext(LocalizationContext);

  const handlePrevious = () => {
    setCurrentItem((prevItem) =>
      prevItem === 0 ? items.length - 1 : prevItem - 1
    );
  };

  const handleNext = () => {
    setCurrentItem((prevItem) =>
      prevItem === items.length - 1 ? 0 : prevItem + 1
    );
  };

  return (
    <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
      <Flex
        sx={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentItem * 100}%)`,
        }}
      >
        {items.map(({ title, description }) => (
          <Flex key={title} sx={{ width: "100%", flexShrink: 0 }}>
            <UspItem title={title} description={description} carousel={true} />
          </Flex>
        ))}
      </Flex>
      {items.length > 1 && (
        <Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: 0,
              cursor: "pointer",
            }}
            onClick={handlePrevious}
            aria-label={translate("uspLiftUps.previous")}
          >
            <CarouselPrevIcon fill={colors.darkGrey} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 0,
              cursor: "pointer",
            }}
            onClick={handleNext}
            aria-label={translate("uspLiftUps.next")}
          >
            <CarouselNextIcon fill={colors.darkGrey} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const GridView = ({ items }) => {
  return (
    <Grid
      sx={{
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
        gap: 5,
      }}
    >
      {items.map(({ title, description }) => {
        return <UspItem title={title} description={description} key={title} />;
      })}
    </Grid>
  );
};

const UspItem = ({ title, description, carousel }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        px: carousel ? 4 : 0,
      }}
    >
      <StarIcon />
      <Themed.h3
        sx={{
          textAlign: "center",
          color: "primary",
          mt: 2,
          mb: 1,
        }}
      >
        {title}
      </Themed.h3>
      <Themed.p
        sx={{
          textAlign: "center",
          my: 1,
        }}
      >
        {description}
      </Themed.p>
    </Flex>
  );
};

const UspLiftUps = ({ data }) => {
  const { uspLiftUps } = data;
  const { width: viewPortWidth } = useWindowSize();

  if (!uspLiftUps || uspLiftUps.length === 0) {
    return null;
  }

  const uspLiftUpsItems = uspLiftUps.slice(0, 3);

  return (
    <Box
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "primary",
        py: 4,
        mt: 2,
      }}
    >
      {isMobile(viewPortWidth) ? (
        <CarouselView items={uspLiftUpsItems} />
      ) : (
        <GridView items={uspLiftUpsItems} />
      )}
    </Box>
  );
};

export default UspLiftUps;
