/** @jsx jsx */
import { Box, jsx, Themed } from "theme-ui";
import RichText2 from "../components/RichText2";

const Types = {
  BOXES: "boxes",
};

const LiftUpTextBody = ({
  type,
  title,
  content,
  locale,
  language,
  ...props
}) => {
  switch (type) {
    case Types.BOXES:
      return (
        <Box
          sx={{
            "h3:first-of-type": { mt: 3 },
          }}
        >
          <Themed.h3>{title}</Themed.h3>
          <RichText2 content={content} locale={locale} language={language} />
        </Box>
      );
    default:
      return (
        <Box>
          <Themed.h3>{title}</Themed.h3>
          <RichText2 content={content} locale={locale} language={language} />
        </Box>
      );
  }
};

export default LiftUpTextBody;
