/** @jsx jsx */
import { jsx, Flex, Box, Themed, Button } from "theme-ui";
import Link from "./Link";

const F = (props) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 304.647 610.231"
    sx={{ display: "block" }}
  >
    <path
      d="m304.647 0v72.178c-61.867 0-112.485 60.929-112.485 136.857h-79.677c0-115.298 86.238-209.035 192.162-209.035m0 200.6v72.178c-61.867 0-112.485 60.929-112.485 136.857v200.6h-79.677v-200.6c0-74.99-50.618-136.857-112.485-136.857v-72.178c61.867 0 117.172 31.871 152.792 81.552 34.683-49.683 89.988-81.552 151.855-81.552"
      fill="#ececec"
    />
  </Box>
);
const E = (props) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360.945 723"
    sx={{ display: "block" }}
  >
    <path
      d="m360.945 485.332v-85.516c-73.3 0-133.272-72.189-133.272-162.147v-237.669h-94.4v237.668c-.001 88.848-59.973 162.148-133.273 162.148v85.516c73.3 0 138.825-37.76 181.028-96.622 41.092 58.862 106.617 96.622 179.917 96.622"
      opacity=".279"
    />
  </Box>
);

const N = (props) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360.945 748.037"
    sx={{ display: "block" }}
  >
    <path
      d="m360.945 502.137v-88.476c-73.3 0-133.272-74.689-133.272-167.762v-245.899h-94.4v245.9c-.001 91.923-59.973 167.761-133.273 167.761v88.477c73.3 0 138.825-39.068 181.028-99.968 41.092 60.9 106.617 99.968 179.917 99.968"
      opacity=".279"
      transform="matrix(-1 0 0 -1 360.945 748.037)"
    />
  </Box>
);

const I = (props) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 624.308 1279.519"
    sx={{ display: "block" }}
  >
    <path
      d="m624.308 1279.519v-151.341c-126.782 0-230.514-127.755-230.514-286.958h-163.28c0 241.752 176.727 438.3 393.794 438.3m0-420.61v-151.342c-126.782 0-230.514-127.755-230.514-286.958v-420.61h-163.28v420.61c0 157.237-103.731 286.958-230.514 286.958v151.341c126.783 0 240.118-66.826 313.114-171 71.075 104.17 184.411 171 311.193 171"
      opacity=".279"
      transform="matrix(-1 0 0 -1 624.308 1279.519)"
    />
  </Box>
);

const baseBG = {
  position: "absolute",
  height: "100%",
  width: "100%",
  right: 0,
  zIndex: 0,
  opacity: 0.12,
  objectFit: "cover",
};
const OmaFenniaCard = ({ data, anchor }) => {
  const { title, icon, description, additionalDetailsLinks, loginLink } = data;
  return (
    <Box
      sx={{
        bg: "muted",
        position: "relative",
        overflow: "hidden",
        scrollMarginTop: "104px",
      }}
      id={anchor}
    >
      <Flex
        sx={{
          zIndex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          height: "100%",
          px: 3,
          py: 4,
        }}
      >
        <Box>
          <Themed.h2
            sx={{
              marginTop: 0,
              zIndex: 1,
            }}
          >
            {title}
          </Themed.h2>
          <Themed.p
            sx={{
              zIndex: 1,
            }}
          >
            {description}
          </Themed.p>
          <Flex
            sx={{
              flexDirection: "column",
              py: 2,
              marginBottom: 5,
              zIndex: 1,
            }}
          >
            {additionalDetailsLinks &&
              additionalDetailsLinks.map(({ title, slug, url }) => {
                return (
                  <Link
                    key={title}
                    target={slug || url}
                    showArrow
                    sx={{
                      mb: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Link>
                );
              })}
          </Flex>
        </Box>
        <Box sx={{ flex: "none" }}>
          <Link
            variant="buttons.primary"
            target={loginLink.slug || loginLink.url}
          >
            {loginLink.title}
          </Link>
        </Box>
      </Flex>
      {icon && icon === "F" && (
        <Box
          sx={{
            ...baseBG,
            width: "70%",
            opacity: 1,
          }}
        >
          <F />
        </Box>
      )}
      {icon && icon === "E" && (
        <Box
          sx={{
            ...baseBG,
            width: "70%",
            top: "-60px",
          }}
        >
          <E />
        </Box>
      )}
      {icon && icon === "N" && (
        <Box
          sx={{
            ...baseBG,
            width: "100%",
            top: "-120px",
          }}
        >
          <N />
        </Box>
      )}
      {icon && icon === "I" && (
        <Box
          sx={{
            ...baseBG,
            width: "100%",
            top: "0px",
          }}
        >
          <I />
        </Box>
      )}
    </Box>
  );
};

export default OmaFenniaCard;
