import { useStaticQuery, graphql } from "gatsby";

export const useAllContentfulOffice = () => {
  const { allContentfulOffice } = useStaticQuery(
    graphql`
      query allOffices {
        allContentfulOffice(
          filter: { node_locale: { ne: "en-US" } }
          sort: { fields: node_locale, order: ASC }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              title
              notification
              content {
                raw
                references {
                  ... on ContentfulContact {
                    id
                    contentful_id
                    __typename
                    phone
                    description
                    title
                    contentful_id
                    visibleTitle
                    node_locale
                    smallMargin
                    internal {
                      type
                    }
                    link {
                      title
                      template
                      slug
                      navigationTitle
                      pageType
                      page {
                        slug
                        title
                        id
                        contentful_id
                        __typename
                      }
                      product_page {
                        id
                        contentful_id
                        __typename
                        title
                        slug
                      }
                    }
                  }

                  ... on ContentfulPage {
                    id
                    contentful_id
                    __typename
                    slug
                  }
                  ... on Node {
                    ...fragmentContentfulCallToAction
                  }
                }
              }
              # companyCustomerContent {
              #   raw
              #   references {
              #     ... on ContentfulContact {
              #       id
              #       contentful_id
              #       __typename
              #       phone
              #       description
              #       title
              #       contentful_id
              #       visibleTitle
              #       node_locale
              #       smallMargin
              #       internal {
              #         type
              #       }
              #       link {
              #         title
              #         template
              #         slug
              #         navigationTitle
              #         pageType
              #         page {
              #           slug
              #           title
              #           id
              #           contentful_id
              #           __typename
              #         }
              #         product_page {
              #           id
              #           contentful_id
              #           __typename
              #           title
              #           slug
              #         }
              #       }
              #     }
              #   }
              # }
              region
              node_locale
            }
          }
        }
      }
    `
  );

  const fiOffices = allContentfulOffice.edges.filter(
    (edge) => edge.node.node_locale === "fi-FI"
  );
  const svOffices = allContentfulOffice.edges.filter(
    (edge) => edge.node.node_locale === "sv-SE"
  );

  return {
    fi: fiOffices,
    sv: svOffices,
  };
};
