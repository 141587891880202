/** @jsx jsx */
import { jsx, Flex, Themed, Button, Box } from "theme-ui";
import { useState, useContext } from "react";
import { getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";
import { sendInsuranceDropdownEvent } from "../utils/analytics";
import { Select } from "./Select";
import VisuallyHidden from "./VisuallyHidden";
import { ExternalLinkIcon } from "./Icon";

const InsuranceDropdown = ({ data, language, anchor }) => {
  const { title, placeholder, selection, button, defaultUrl } = data;
  const options = selection.map(sel => sel.name);
  const [selected, setSelected] = useState();
  const value = selected;
  const nodes = useSitePages();
  const changeSelection = e => {
    setSelected(e.target.value);
  };
  const redirect = e => {
    e.preventDefault();
    const link = selection.find(sel => sel.name === selected);
    if (link) {
      let to = link.url;
      if (link.slug) {
        to = getUrlFromSlug(link.slug, nodes);
        sendInsuranceDropdownEvent(selected, to, language);
        window.location = to;
        setSelected(null);
        return;
      } else {
        sendInsuranceDropdownEvent(selected, to, language);
      }
      window.open(to, "_blank");
    }
  };

  const redirectDefault = e => {
    e.preventDefault();
    const target = defaultUrl;
    sendInsuranceDropdownEvent(selected, target, language);
    window.open(target, "_self" );
  };

  if (!selection || !title || !button) return null;
  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        bg: "primary",
        p: [3, 4],
        color: "white",
        scrollMarginTop: "104px"
      }}
      id={anchor}
    >
      <Themed.h2
        sx={{
          color: "white",
          mt: 0,
          mb: 4,
        }}
      >
        {title}
      </Themed.h2>
      <VisuallyHidden>
        <label htmlFor="insuranceDropdown">{placeholder}</label>
      </VisuallyHidden>
      <Select
        id="insuranceDropdown"
        value={value ? value : ""}
        onChange={changeSelection}
        sx={{
          bg: "white",
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((opt, i) => (
          <option key={`dropdown-option-${i}`}>{opt}</option>
        ))}
      </Select>
      {selected ? 
      <Box sx={{ mt: 4 }}>
        <Button
          variant="light"
          onClick={redirect}
          sx={{ whiteSpace: "normal", textAlign: "left" }}
        >
          {button}
          <ExternalLinkIcon sx={{ display: "inline", marginLeft: 10}}/>
        </Button>
      </Box> :
      <Box sx={{ mt: 4 }}>
      <Button
        variant="light"
        onClick={redirectDefault}
        sx={{ whiteSpace: "normal", textAlign: "left" }}
      >
        {button}
      </Button>
        </Box> }
    </Flex>
  );
};

export default InsuranceDropdown;
