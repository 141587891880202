/** @jsx jsx */
import { jsx } from "theme-ui";
import ContentCardList, { ContentCardTypes } from "./ContentCardList";
import { useAllContentfulContentHubPages } from "../hooks/useAllContentfulContentHubPages";
import { filterContentHubPages } from "../hooks/useContentHubQueryParamFiltering";
import { isBrowser } from "../utils/utils";

const generateSummaryPageContentHubParams = ({
  themes = [],
  contentTypes = [],
  topics = [],
  authors = [],
}) => {
  const queryParams = new URLSearchParams();

  themes.forEach((theme) => {
    queryParams.append(
      Object.keys({ theme })[0].toLocaleLowerCase(),
      theme.toLowerCase()
    );
  });

  contentTypes.forEach((type) => {
    queryParams.append(
      Object.keys({ type })[0].toLocaleLowerCase(),
      type.toLocaleLowerCase()
    );
  });

  topics.forEach((topic) => {
    queryParams.append(
      Object.keys({ topic })[0].toLocaleLowerCase(),
      topic.toLocaleLowerCase()
    );
  });

  authors.forEach((author) => {
    queryParams.append(
      Object.keys({ author })[0].toLocaleLowerCase(),
      author.toLocaleLowerCase()
    );
  });

  return "/?" + queryParams.toString();
};

const mapFromRawValues = (arr, testValue) => {
  return arr ? arr.map((item) => item[testValue]) : [];
};

const ContentHubLiftUps = ({
  title,
  rawThemes,
  rawContentTypes,
  rawTopics,
  rawAuthors,
  language,
  locale,
  summaryPageSlug,
}) => {
  const allContentHubPages = useAllContentfulContentHubPages();
  const currentSlugShouldNotBeInLiftUps =
    isBrowser && window.location.pathname.split("/");
  const { themes, contentTypes, topics, authors } = ((
    rawThemes,
    rawContentTypes,
    rawTopics,
    rawAuthors
  ) => {
    const themes = mapFromRawValues(rawThemes, "theme");
    const contentTypes = mapFromRawValues(rawContentTypes, "name");
    const topics = mapFromRawValues(rawTopics, "name");
    const authors = mapFromRawValues(rawAuthors, "name");

    return { themes, contentTypes, topics, authors };
  })(rawThemes, rawContentTypes, rawTopics, rawAuthors);

  const displayPages = filterContentHubPages(
    allContentHubPages[language],
    themes,
    contentTypes,
    topics,
    authors
  ).filter(
    (page) =>
      Array.isArray(currentSlugShouldNotBeInLiftUps) &&
      !currentSlugShouldNotBeInLiftUps.some((url) => url === page.node.slug) &&
      !currentSlugShouldNotBeInLiftUps.some(
        (url) => url === `${page.node.slug}/`
      )
  );

  const summaryPageContentHubUrl = generateSummaryPageContentHubParams({
    themes,
    contentTypes,
    topics,
    authors,
  });

  if (displayPages.length < 1) return null;

  return (
    <ContentCardList
      title={title}
      type={ContentCardTypes.GENERIC}
      targetSlug={
        summaryPageSlug && "/" + summaryPageSlug + summaryPageContentHubUrl
      }
      contentHubPages={displayPages.slice(0, 3)}
    />
  );
};

export default ContentHubLiftUps;
