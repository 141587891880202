/** @jsx jsx */
import { jsx, Flex, Themed } from "theme-ui";
import Link from "./Link";

const FolksamLinks = ({ data, anchor }) => {
  const { title, description, links } = data;
  return (
    <Flex
      sx={{
        flexDirection: ["column", null, null, "row"],
        bg: "muted",
        p: 4,
        scrollMarginTop: "104px",
      }}
      id={anchor}
    >
      <Flex
        sx={{
          flexDirection: "column",
          width: ["100%", null, null, "50%"],
          mb: [3, null, null, 0],
        }}
      >
        <Themed.h3 sx={{ my: 1 }}>{title}</Themed.h3>
        {description}
      </Flex>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
        }}
      >
        {links.map(({ title, link }, index) => {
          const { title: linkTitle, slug, url } = link;
          return (
            <Flex
              sx={{
                flexDirection: "column",
                px: [0, null, null, 4],
                marginTop: "auto",
                mr: index === 0 ? 4 : 0,
                mt: index === 0 ? 0 : [3, 0],
              }}
              key={title}
            >
              <Themed.h4 sx={{ my: 1 }}>{title}</Themed.h4>
              <Link
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
                target={slug || url}
                showArrow
              >
                {linkTitle}
              </Link>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
export default FolksamLinks;
