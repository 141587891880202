/** @jsx jsx */
import { jsx, Flex, Box, Themed } from "theme-ui";
import Link from "./Link";

const AttachmentLink = props => (
  <Link
    {...props}
    sx={{
      display: "inline-block",
      width: ["100%", "50%"],
      mx: 3,
      mb: [2, 0],
      fontWeight: "bold",
    }}
  >
    {props.children}
  </Link>
);

const Attachements = ({ title, attachements, urls, locale }) => {
  if (!attachements && !urls) return null;
  return (
    <Box>
      <Themed.h2>{title}</Themed.h2>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          mx: -3,
        }}
      >
        {urls &&
          urls.map((url, index) => {
            if (!url.fields.url || !url.fields.url[locale]) return null;
            return (
              <AttachmentLink
                key={url.fields.url[locale]}
                target={url.fields.url[locale] || url.fields.url["fi-FI"]}
                title={url.fields.title[locale] || url.fields.title["fi-FI"]}
                sx={{ fontWeight: "bold" }}
              >
                {url.fields.title[locale] || url.fields.title["fi-FI"]}
              </AttachmentLink>
            );
          })}

        {attachements &&
          attachements.map((attachement, index) => {
            if (!attachement.fields.file || !attachement.fields.file[locale])
              return null;
            return (
              <AttachmentLink
                key={attachement.fields.title[locale]}
                target={
                  attachement.fields.file[locale].url ||
                  attachement.fields.file["fi-FI"].url
                }
                title={
                  attachement.fields.file[locale].url ||
                  attachement.fields.file["fi-FI"].url
                }
              >
                {attachement.fields.title[locale] ||
                  attachement.fields.title["fi-FI"]}
              </AttachmentLink>
            );
          })}
      </Flex>
    </Box>
  );
};

export default Attachements;
