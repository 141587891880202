import React from "react";
import { Box } from "theme-ui";
import { CaretIcon } from "./Icon";

const getProps = test => props => {
  const next = {};
  for (const key in props) {
    if (test(key || "")) next[key] = props[key];
  }
  return next;
};

const MRE = /^m[trblxy]?$/;

const getMargin = getProps(k => MRE.test(k));
const omitMargin = getProps(k => !MRE.test(k));

export const Select = React.forwardRef((props, ref) => (
  <Box
    {...getMargin(props)}
    sx={{
      display: "flex",
      height: 'auto'
    }}
  >
    <Box
      ref={ref}
      as="select"
      variant="select"
      {...omitMargin(props)}
      __themeKey="forms"
      __css={{
        display: "block",
        width: "100%",
        p: 2,
        appearance: "none",
        fontSize: "inherit",
        lineHeight: "inherit",
        border: "1px solid",
        borderRadius: 4,
        color: "inherit",
        bg: "transparent",
        "::-ms-expand": {
          display: "none"
        }
      }}
    />
    <CaretIcon
      sx={{
        color: "text",
        ml: -3,
        alignSelf: "center",
        pointerEvents: "none",
        transform: "scale(2) ",
        transformOrigin: "top right",
      }}
    />
  </Box>
));
