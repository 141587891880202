/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import LiftUpTextBody from "./LiftUpTextBody";

const LiftUpBox = ({ target, locale, language, ...props }) => {
  const itemCount = target.box.length;
  const itemsPerRow = target.itemsPerRow;

  const flexBasisSetter = () => {
    if (itemCount === 4 && itemsPerRow === 2) {
      return ["0%", "45%", "45%", "45%"];
    } else if (itemCount === 4) {
      return ["0%", "45%", "21%", "21%"];
    } else {
      return "0%";
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: ["column", "row", "row", "row"],
        flexWrap: ["nowrap", "wrap", "wrap", "wrap"],
        gap: 3,
      }}
    >
      {target.box &&
        target.box.map((box, index) => {
          return (
            <Flex
              key={index}
              sx={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: flexBasisSetter(),
                bg: "muted",
                borderTop: "solid 12px",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
                borderTopColor: "primary",
                wordWrap: "break-word",
                px: 3,
                pb: 1,
                mb: [4, null, 0],
              }}
            >
              <LiftUpTextBody
                type="boxes"
                key={box.title}
                title={box.title}
                content={box.content}
                locale={locale}
                language={language}
              />
            </Flex>
          );
        })}
    </Flex>
  );
};

export default LiftUpBox;
