/** @jsx jsx */
import { Box, Flex, jsx, Themed } from "theme-ui";
import Link from "./Link";
import { generateAnalyticsId, sendDamageReportEvent } from "../utils/analytics";

const AccidentTypeQuickSelection = ({ data, anchor, ...rest }) => {
  const { quickSelections, allSelections, title } = data;
  return (
    <Flex
      sx={{
        flexWrap: "wrap",
        mb: 1,
        p: [3, 4],
        bg: "muted",
        scrollMarginTop: "104px",
      }}
      id={anchor}
    >
      <Themed.h2 sx={{ mt: 0, mb: 3, width: "100%" }}>{title}</Themed.h2>

      {quickSelections.map(
        (
          { title, slug, url, analyticsAction, analyticsId, contentful_id },
          index
        ) => {
          const dataAnalyticsId = generateAnalyticsId(
            analyticsId,
            contentful_id,
            slug ? slug : url
          );
          return (
            <Link
              key={index}
              variant="buttons.secondaryDark"
              analyticsAction={analyticsAction}
              analyticsId={analyticsId}
              {...rest}
              title={title}
              target={slug ? slug : url}
              sx={{ mr: 2, mb: 2, whiteSpace: "normal" }}
              onClick={() => sendDamageReportEvent(dataAnalyticsId)}
            >
              {title}
            </Link>
          );
        }
      )}

      <Box>
        <Link
          variant="buttons.primary"
          {...rest}
          title={allSelections.title}
          analyticsAction={allSelections.analyticsAction}
          analyticsId={allSelections.analyticsId}
          target={allSelections.slug ? allSelections.slug : allSelections.url}
        >
          {allSelections.title}
        </Link>
      </Box>
    </Flex>
  );
};

export default AccidentTypeQuickSelection;
