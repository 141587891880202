import React from "react";
import { jsx, Flex, Box } from "theme-ui";
import { WarningIcon } from "./Icon";
import dayjs from "dayjs";
import Link from "./Link";
import colors from "../gatsby-plugin-theme-ui/colors";

const currentDateTime = dayjs();

const ErrorTypes = {
  Info: "informational",
  Warning: "faultNotificationBackground",
  Error: "error",
};

const EmbeddedNotification = ({
  message,
  severity,
  validFrom,
  validTo,
  linkText,
  linkUrl,
}) => {
  const validFromDateTime = dayjs(validFrom);
  const validToDateTime = dayjs(validTo);
  const notificationBackgroundColor = ErrorTypes[severity];
  const notificationTextColor =
    ErrorTypes[severity] === ErrorTypes.Warning ? "text" : "white";
  if (
    validFromDateTime.isBefore(currentDateTime) &&
    validToDateTime.isAfter(currentDateTime)
  ) {
    return (
      <Flex
        sx={{
          flexDirection: "row",
          backgroundColor: notificationBackgroundColor,
          color: notificationTextColor,
          p: 2,
          mb: 4,
          width: "100%",
        }}
      >
        <WarningIcon
          sx={{
            fill: notificationTextColor,
            alignSelf: "center",
            minWidth: "16px",
          }}
        />
        <Box
          sx={{
            ml: 3,
          }}
        >
          {message}
        </Box>
        {linkUrl ? (
          <Link
            target={linkUrl}
            sx={{
              color: severity === "Warning" ? colors.primary : colors.notice,
              ml: 1,
              fontWeight: "bold",
              "&:hover": {
                color:
                  severity === "Warning" ? colors.hover : colors.lightGreen,
              },
            }}
          >
            {linkText}
          </Link>
        ) : (
          ""
        )}
      </Flex>
    );
  }
  return null;
};

export default EmbeddedNotification;
