import React from "react";
import InsuranceProductTable from "./InsuranceProductTable";
import Table from "./Table";
import AccidentTypeQuickSelection from "./AccidentTypeQuickSelection";
import InsuranceDropdown from "./InsuranceDropdown";
import ArticleList from "./ArticleList";
import Iframe from "./Iframe";
import Form from "./Form";
import FolksamLinks from "./FolksamLinks";
import RegionOffices from "./RegionOffices";
import OmaFenniaCard from "./OmaFenniaCard";
import ListOfLinks from "./ListOfLinks";
import EmbeddedScript from "./EmbeddedScript";
import MostRecentContentHubPages from "./MostRecentContentHubPages";
import LifeInsuranceCalculator from "./LifeInsuranceCalculator";
import DynamicSelectors from "./DynamicSelectors";
import UspLiftUps from "./UspLiftUps";
import DropdownLinkList from "./DropdownLinkList";

const ContentfulComponent = ({
  template,
  title,
  data,
  anchor,
  type,
  selectedValue,
  callback,
  ...rest
}) => {
  const selectComponent = (template) => {
    switch (template) {
      case "InsuranceProductTable":
        return (
          <InsuranceProductTable data={data} anchor={anchor} type={type} />
        );
      case "AccidentTypeQuickSelection":
        return (
          <AccidentTypeQuickSelection
            title={title}
            data={data}
            anchor={anchor}
          />
        );
      case "InsuranceDropdown":
        return (
          <InsuranceDropdown
            title={title}
            data={data}
            anchor={anchor}
            {...rest}
          />
        );
      case "DropdownLinkList":
        return (
          <DropdownLinkList
            title={title}
            data={data}
            anchor={anchor}
            {...rest}
          />
        );
      case "ArticleList":
        return (
          <ArticleList title={title} data={data} anchor={anchor} {...rest} />
        );
      case "Form":
        return <Form title={title} data={data} anchor={anchor} {...rest} />;
      case "Iframe":
        return <Iframe data={data} anchor={anchor} />;
      case "FolksamLinks":
        return <FolksamLinks data={data} anchor={anchor} />;
      case "OmaFenniaCard":
        return <OmaFenniaCard data={data} anchor={anchor} />;
      case "RegionOffices":
        return <RegionOffices data={data} anchor={anchor} {...rest} />;
      case "Table":
        return <Table data={data} anchor={anchor} />;
      case "ListOfLinks":
        return <ListOfLinks data={data} anchor={anchor} />;
      case "EmbeddedScript":
        return <EmbeddedScript data={data} anchor={anchor} />;
      case "MostRecentContentHubPages":
        return <MostRecentContentHubPages {...rest} />;
      case "Calculator":
        return <LifeInsuranceCalculator type={type} data={data} {...rest} />;
      case "DynamicSelectors":
        return <DynamicSelectors type={type} data={data} selectedValue={selectedValue} callback={callback} {...rest} />;
      case "UspLiftUps":
        return <UspLiftUps data={data} anchor={anchor} />;
      default:
        return null;
    }
  };
  return <>{selectComponent(template)}</>;
};

export default ContentfulComponent;
