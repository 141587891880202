/* Themed material-UI calculator input components */
import React from "react";
// Dropdown imports
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// Textfield import
import TextField from "@material-ui/core/TextField";
// Slider imports
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
//CheckBox import
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Themed } from "theme-ui";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Add thousand separators for dropdown values, if it is presumed the values is not a year (a lousy solution... should be refactored before the year 2050...)
function formatNumber(x) {
  if (typeof x === "number" && x > 2050) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";
  } else {
    return x;
  }
}

// Dropdown component
export const SelectField = (props) => (
  <FormControl className={props.className} variant="outlined">
    {props.label ? <InputLabel id={props.label}>{props.label}</InputLabel> : ""}
    <Select
      labelId={props.label}
      label={props.label}
      id="select"
      value={props.stateValue}
      onChange={props.onChange}
      style={{ color: "#008a28", fontSize: "1.1em", color: "green" }}
    >
      {props.values.map((value, index) => {
        return (
          <MenuItem key={index} value={value} style={{ color: "#008a28" }}>
            {formatNumber(value)}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);

// TextField component
export const TextInput = (props) => (
  <form noValidate autoComplete="off">
    <TextField
      id={props.label}
      label={props.label}
      variant="outlined"
      className={props.className}
      onChange={props.onChange}
    />
  </form>
);

// Slider with value visible
export const ValueSlider = (props) => {
  return (
    <div>
      <Typography id={props.label} gutterBottom>
        {props.label}
      </Typography>
      <Slider
        defaultValue={props.default}
        aria-labelledby={props.id}
        step={props.step}
        valueLabelDisplay="on"
        max={props.max}
        min={props.min}
        onChangeCommitted={props.onChange}
      />
    </div>
  );
};

// Checkbox component
const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#008a28",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CheckBox = (props) => {
  return (
    <Tooltip title={props.tooltip} aria-label={props.name}>
      <FormControlLabel
        control={
          <React.Fragment>
            <GreenCheckbox
              checked={props.stateValue}
              onChange={props.onChange}
              name={props.name}
            />
            <Themed.p style={{ fontSize: "0.75em" }}>{props.label}</Themed.p>
          </React.Fragment>
        }
        label={""}
      />
    </Tooltip>
  );
};
