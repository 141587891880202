/** @jsx jsx */
import { jsx, Box, Themed, Button } from "theme-ui";
import React, { useState, useEffect, useRef } from "react";
import RichText2 from "./RichText2";
import { ArrowDown } from "./Icon";
import { motion } from "framer-motion";
import { isBrowser, getPageHash } from "../utils/utils.js";
import { v4 as uuidv4 } from "uuid";

const Accordion = ({
  title,
  content,
  index,
  locale,
  language,
  anchorLink,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams.includes("print")) {
      setExpanded(true);
    }
  }, []);

  const [accordionId, setAccordionId] = useState();
  useEffect(() => {
    setAccordionId(uuidv4());
  }, []);

  const ariaControlId =
    index >= 0
      ? `accordion-${accordionId}-${index}`
      : `accordion-${accordionId}`;

  const buttonRef = useRef();
  const hash = getPageHash();

  useEffect(() => {
    if (hash !== null && anchorLink !== null && hash === anchorLink) {
      setExpanded(true);
      // hack for gatsby route change
      setTimeout(() => {
        if (isBrowser && window && buttonRef && buttonRef.current) {
          window.scrollTo(0, buttonRef.current.offsetTop - 100);
        }
      }, 100);
    }
  }, [hash, buttonRef, anchorLink]);

  return (
    <Box
      id={anchorLink ? anchorLink : ""}
      sx={{
        borderColor: "grey",
        borderBottom: "solid .5px",
      }}
      {...props}
    >
      <Button
        onClick={() => setExpanded(expanded ? false : true)}
        //onKeyPress={handleKeyPress}
        role="button"
        aria-controls={ariaControlId}
        aria-expanded={expanded}
        variant="plain"
        ref={buttonRef}
        sx={{
          display: "flex",
          py: 3,
          width: "100%",
          color: "text",
          whiteSpace: "normal",
          textAlign: "left",
          ":hover": { color: "text", opacity: 0.9 },
        }}
      >
        <motion.div
          style={{
            marginTop: ".125em",
            width: 24,
            height: 24,
            marginLeft: 5,
            transformOrigin: "center center",
          }}
          animate={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <ArrowDown />
        </motion.div>

        <Themed.h3
          as={motion.header}
          initial={false}
          sx={{
            pl: 1,
          }}
        >
          {title}
        </Themed.h3>
      </Button>
      <motion.section
        key="content"
        initial="collapsed"
        animate={expanded ? "open" : "collapsed"}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: "auto", display: "block" },
          collapsed: {
            opacity: 0,
            height: 0,
            overflow: "hidden",
            transitionEnd: {
              display: "none",
            },
          },
        }}
        transition={{ duration: 0.3, ease: [0.04, 0.6, 0.2, 1] }}
        id={ariaControlId}
      >
        <motion.div
          variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
          transition={{ duration: 0.15 }}
        >
          <Box
            sx={{
              pl: 4,
              pb: 2,
              "*:first-child": { mt: 0 },
            }}
          >
            <RichText2 content={content} locale={locale} language={language} />
          </Box>
        </motion.div>
      </motion.section>
    </Box>
  );
};
export default Accordion;
